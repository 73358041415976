import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import { MeContext } from '../../../me/context/context';
import CashboxesModule from '../../cashboxes';
import { CashFlowOverviewPage } from '../pages';

export const CashboxesRouter = () => {
  const { can } = useContext(MeContext);

  return (
    <Switch>
      <Route path='/cash-flow' component={CashFlowOverviewPage} />
      {can(109) && <Route path='/cash-flow/cashboxes' component={CashboxesModule} />}
    </Switch>
  );
};
