import { message } from 'antd';
import { handlebars } from 'hbs';

import { getBase64 } from '../../../shared/utils/get-base-64';

//@ts-ignore
import documentTemplate from '../templates/document.hbs';
import { AppointmentsService } from '../services';
import { AppointmentMappers } from '../mappers';
import callCenter from '../../analyzes/assets/call-center.png';
import logo from '../../analyzes/assets/diamond.png';
import slogan from '../../analyzes/assets/slogan.png';
import qcmd from '../../analyzes/assets/qcmd.png';
import riqas from '../../analyzes/assets/riqas.png';
import bg from '../../analyzes/assets/diamond-bg.png';

handlebars.registerHelper('inc', function (value) {
  return parseInt(value) + 1;
});

handlebars.registerHelper('ifEquals', function (arg1, arg2, options) {
  // @ts-ignore
  // eslint-disable-next-line eqeqeq
  return arg1 == arg2 ? options.fn(this) : options.inverse(this);
});

export const printAppointmentDocumentUseCase = async (id: string | number) => {
  const key = Math.round(Math.random() * 1000000).toString();
  message.loading({ key, content: 'Sənəd hazırlanır.' });

  try {
    const template = await fetch('/' + process.env.REACT_APP_CLIENT_FOLDER + '/appointment-document.hbs')
      .then((r) => {
        return r.text();
      })
      .catch((e) => {
        return fetch(documentTemplate).then((r) => r.text());
      });
    const logoImage = await fetch(logo).then((r) => r.blob());
    const callCenterImage = await fetch(callCenter).then((r) => r.blob());
    const sloganImage = await fetch(slogan).then((r) => r.blob());
    const qcmdImage = await fetch(qcmd).then((r) => r.blob());
    const riqasImage = await fetch(riqas).then((r) => r.blob());
    const bgImage = await fetch(bg).then((r) => r.blob());

    const result = await AppointmentsService.getOneById(id);
    const queues = await AppointmentsService.getQueues(id);

    if (result.status !== 200) {
      message.error({ key, content: result.data });
      return false;
    }

    if (queues.status !== 200) {
      message.error({ key, content: queues.data });
      return false;
    }

    const mappedData = {
      hospitalLogo: process.env.REACT_APP_CLIENT_LOGO || "/main_logo.png",
      appointment: AppointmentMappers.toPrint(result.data, queues.data),
      logo: await getBase64(logoImage),
      callCenter: await getBase64(callCenterImage),
      slogan: await getBase64(sloganImage),
      qcmdLogo: await getBase64(qcmdImage),
      riqasLogo: await getBase64(riqasImage),
      bg: await getBase64(bgImage),
    };
    const renderedTemplate = handlebars.compile(template)(mappedData);

    const printWindow = window.open();

    if (printWindow) {
      printWindow.document.write(renderedTemplate);
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print();
        printWindow.onfocus = () => {
          setTimeout(() => printWindow.close(), 0);
        };
      }, 500);
    } else {
      message.error({ key, content: 'Brauzerdə xəta baş verdi.' });
      return false;
    }
  } catch (e) {
    message.error({ key, content: 'Şablon əldə edilə bilmədi.' });
  }
};
