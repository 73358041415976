import { caller } from '../../../shared/utils/caller';
import { urlMaker } from '../../../shared/utils/url-maker';
import { ApiResponse } from '../../../shared/utils/api-response';
import { appendToFormData } from '../../../shared/utils/apply-to-form-data';
import { NextTableMetaResponse } from '../../../shared/modules/next-table/types';
import { nextTableMetaMapper } from '../../../shared/modules/next-table/utils/meta-mapper';

import { CashRegisterMapper } from '../mappers';
import { CashRegisterLastShifts, CashRegisterShift } from '../types/shift';

export class CashRegisterShiftsService {
  public static async getLast(): Promise<ApiResponse<200, CashRegisterLastShifts> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/device/last_shift');

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        const mappedData = CashRegisterMapper.Shifts.lastsFromApi(data);

        return new ApiResponse(200, mappedData, null);
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }

  public static async getAll(
    query?: Record<string, any>,
  ): Promise<ApiResponse<200, CashRegisterShift[], NextTableMetaResponse> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/device/shift_logs', query);

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data, ...meta } = await response.json();
        return new ApiResponse(200, CashRegisterMapper.Shifts.manyFromApi(data), nextTableMetaMapper(meta));
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }

  public static async close(): Promise<ApiResponse<200> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/device/closeShift');

    try {
      const response = await caller(url, { method: 'POST' });

      if (response.ok) {
        return new ApiResponse(200, null, null);
      } else if (response.status === 400 || response.status === 422) {
        const { errors } = await response.json();
        const mappedErrors = Object.values<string[]>(errors).flat().join(', ');

        return new ApiResponse(400, mappedErrors, null);
      } else {
        return new ApiResponse(400, 'Əməliyyat aparıla bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }

  public static async open(initialAmount: number | string): Promise<ApiResponse<200> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/device/openShift');
    const body = new FormData();
    appendToFormData({ sum: initialAmount || '0' }, body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResponse(200, null, null);
      } else if (response.status === 400 || response.status === 422) {
        const { errors } = await response.json();
        const mappedErrors = Object.values<string[]>(errors).flat().join(', ');

        return new ApiResponse(400, mappedErrors, null);
      } else {
        return new ApiResponse(400, 'Əməliyyat aparıla bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }

  public static async getStatus(): Promise<ApiResponse<200, boolean> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/device/getShiftStatus');

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();

        return new ApiResponse(200, CashRegisterMapper.Shifts.statusFromApi(data), null);
      } else if (response.status === 400 || response.status === 422) {
        const { errors } = await response.json();
        const mappedErrors = Object.values<string[]>(errors).flat().join(', ');

        return new ApiResponse(400, mappedErrors, null);
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }

  public static async getSumById(id: string | number): Promise<ApiResponse<200, number> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/device/cheque_sum', { shift_id: id });

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();

        return new ApiResponse(200, data ? parseFloat(data) : 0, null);
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }
}
