import { caller } from '../../../shared/utils/caller';
import { urlMaker } from '../../../shared/utils/url-maker';
import { ApiResponse } from '../../../shared/utils/api-response';

import { CashRegisterDelayedSalesService } from './delayed-sales';
import { CashRegisterWithdrawsService } from './withdraws';
import { CashRegisterXReportsService } from './x-reports';
import { CashRegisterDepositsService } from './deposits';
import { CashRegisterChecksService } from './checks';
import { CashRegisterShiftsService } from './shifts';
import { CashRegisterSalesService } from './sales';

import { CashRegisterMapper } from '../mappers';
import { CashRegister, CashRegisterSlim, CashRegisterStatus } from '../types';
import { appendToFormData } from '../../../shared/utils/apply-to-form-data';

export class CashRegisterService {
  public static Shifts = CashRegisterShiftsService;
  public static Checks = CashRegisterChecksService;
  public static Withdraws = CashRegisterWithdrawsService;
  public static Deposits = CashRegisterDepositsService;
  public static Sales = CashRegisterSalesService;
  public static DelayedSales = CashRegisterDelayedSalesService;
  public static XReports = CashRegisterXReportsService;

  public static async getDetails(): Promise<ApiResponse<200, CashRegister> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/device/getInfo');

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        const mappedData = CashRegisterMapper.detailsFromApi(data);

        return new ApiResponse(200, mappedData, null);
      } else if (response.status === 422 || response.status === 400) {
        const { errors } = await response.json();
        const mappedErrors = Object.values<string[]>(errors).flat().join(', ');

        return new ApiResponse(400, mappedErrors, null);
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }

  public static async getPrinterIsConnected(): Promise<ApiResponse<200, CashRegisterStatus> | ApiResponse<403> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/device/isConnectedPrinter');

    try {
      const response = await caller(url, { method: 'POST' });

      if (response.ok) {
        const data = await response.json();
        const mappedData = CashRegisterMapper.statusFromApi(data);

        return new ApiResponse(200, mappedData, null);
      } else if (response.status === 400 || response.status === 422) {
        const { errors } = await response.json();
        const mappedErrors = Object.values<string[]>(errors).flat().join(', ');

        return new ApiResponse(400, mappedErrors, null);
      } else if (response.status === 403) {
        return new ApiResponse(403, null, null);
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }

  public static async getAllDevices(): Promise<ApiResponse<200, CashRegisterSlim[]> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/device/list');

    try {
      const response = await caller(url);

      if (response.ok) {
        const data = await response.json();
        const mappedData = CashRegisterMapper.manyDevicesFromApi(data);

        return new ApiResponse(200, mappedData, null);
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }

  public static async updateCurrentDevice(id: number | string): Promise<ApiResponse<200> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/device/set_device');
    const body = new FormData();
    appendToFormData({ device_id: id }, body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResponse(200, null, null);
      } else if (response.status === 400 || response.status === 422) {
        const { errors } = await response.json();
        const mappedErrors = Object.values<string[]>(errors).flat().join(', ');

        return new ApiResponse(400, mappedErrors, null);
      } else {
        return new ApiResponse(400, 'Əməliyyat aparıla bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }
}
