import { localURLMaker, urlMaker } from '../../../../shared/utils/url-maker';
import { caller } from '../../../../shared/utils/caller';
import { ApiResponse } from '../../../../shared/utils/api-response';
import { Cashbox, CashboxBalance, CashboxFormData, CashboxFormDataApi } from '../types';
import { nextTableMetaMapper } from '../../../../shared/modules/next-table/utils/meta-mapper';
import { NextTableMetaResponse } from '../../../../shared/modules/next-table/types';
import { CashboxMappers } from '../mappers';
import { appendToFormData } from '../../../../shared/utils/apply-to-form-data';
import { formDataFlat } from '../../../../shared/utils/form-data-flat';

export class CashboxesService {
  public static async getAll(
    query: Record<string, any>,
  ): Promise<ApiResponse<200, Cashbox[], NextTableMetaResponse> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/cashboxes', query);

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data, ...meta } = await response.json();
        return new ApiResponse(200, CashboxMappers.manyFromApi(data), nextTableMetaMapper(meta));
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }
  public static async actionByIds(ids: (string | number)[], action: string): Promise<ApiResponse<200> | ApiResponse<400 | 500, string>> {
    const url = urlMaker(localURLMaker('/api/cashboxes/:action', { action }));
    const body = new FormData();

    appendToFormData(formDataFlat({ cashbox_id: ids }), body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResponse(200, null, null);
      } else {
        return new ApiResponse(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async getOneById(id: string | number): Promise<ApiResponse<200, Cashbox> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/cashboxes/info', { cashbox_id: id });

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        return new ApiResponse(200, CashboxMappers.fromApi(data), null);
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi.', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }
  public static async getCurrentBalance(): Promise<ApiResponse<200, CashboxBalance> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/cashboxes/balance');

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        return new ApiResponse(200, CashboxMappers.balanceToDomain(data), null);
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi.', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async createOrUpdate(
    values: CashboxFormData,
    type: string = 'create',
  ): Promise<ApiResponse<200> | ApiResponse<422, CashboxFormData> | ApiResponse<400 | 500, string>> {
    const url = type === 'create' ? urlMaker('/api/cashboxes/create') : urlMaker('/api/cashboxes/edit');
    const body = new FormData();

    appendToFormData<CashboxFormDataApi>(CashboxMappers.formDataToApi(values), body);

    try {
      const response = await caller(url, { body, method: 'POST' });

      if (response.ok) {
        return new ApiResponse(200, null, null);
      } else if (response.status === 422) {
        const { errors } = await response.json();

        return new ApiResponse(422, CashboxMappers.formErrorsFromApi(errors), null);
      } else {
        return new ApiResponse(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }
}
