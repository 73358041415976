import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

import { CustomersPage } from '../pages/main';
import { CreateCustomerPage } from '../pages/create';
import { UpdateCustomerPage } from '../pages/update';
import { CustomerDetailsPage } from '../pages/details';
import { MeContext } from '../../me/context/context';
import { CombineCustomers } from '../containers/combine-customers';

export const CustomersRouter = () => {
  const { can } = useContext(MeContext);

  return <Route>{can(33) && <Route path='/customers' component={CustomersPage} />}</Route>;
};

export const CustomersModalRouter = () => {
  const { can } = useContext(MeContext);

  return (
    <Route>
      {can(35) && <Route path='/customers/create' component={CreateCustomerPage} />}
      {can(36) && <Route path='/customers/:id/update' component={UpdateCustomerPage} />}
      {can(33) && <Route path='/customers/:id/details' component={CustomerDetailsPage} />}
      {can(35) && can(36) && <Route path='/customers/combine' component={CombineCustomers} />}
    </Route>
  );
};
