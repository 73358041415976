import React from 'react';
import { Col, Row } from 'antd';
import { ProgressUI } from '../../../shared/modules/progress/components';
import { useStatisticsCounts } from '../hooks/use-counts';

export const StatisticsCounts = () => {
  const { data, loading } = useStatisticsCounts();

  return (
    <Row gutter={[12, 12]}>
      <Col span={24} sm={12} lg={8}>
        <ProgressUI title='Müştərilər' subTitle='Ümumi say' progress={!loading ? 100 : 0} value={data.customers} />
      </Col>
      <Col span={24} sm={12} lg={8}>
        <ProgressUI title='Həkimlər' subTitle='Ümumi say' progress={!loading ? 100 : 0} value={data.doctors} />
      </Col>
      <Col span={24} sm={12} lg={8}>
        <ProgressUI title='İstifadəçilər' subTitle='Ümumi say' progress={!loading ? 100 : 0} value={data.users} />
      </Col>
      <Col span={24} sm={12} lg={8}>
        <ProgressUI
          title='Satışlar'
          subTitle='Təsdiqlənmiş'
          progress={(data.appointments.approved / data.appointments.all) * 100}
          value={data.appointments.approved}
          bottomText='Cəmi'
          bottomValue={data.appointments.all}
        />
      </Col>
    </Row>
  );
};
