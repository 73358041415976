import React, { useContext } from 'react';
import * as Icons from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { StyledHeaderButton } from '../../../layout/styled/header';
import { AppHeaderPortal } from '../../../layout/components/header-portal';

import { StyledActionBar } from '../../../../shared/styled/action-bar';
import { useCashboxesTableActions } from '../hooks/use-table-actions';
import { MeContext } from '../../../me/context/context';

export const CashboxTableActions = () => {
  const { selection, handleFetch, handleResetSelection, handleSelectAll, handleReset } = useCashboxesTableActions();
  const { can } = useContext(MeContext);

  return (
    <AppHeaderPortal>
      {!!selection.length && (
        <StyledActionBar $visible={!!selection.length} $flex={true}>
          <StyledActionBar.Selection>
            <Icons.CloseCircleTwoTone role='icon' onClick={handleResetSelection} />
            <span>{selection.length} sətir seçilib</span>
          </StyledActionBar.Selection>
        </StyledActionBar>
      )}
      {!selection.length && (
        <StyledActionBar $visible={!selection.length}>
          {can(20) && (
            <Link to='/cash-flow/cashboxes/create'>
              <StyledHeaderButton $ghost={true} icon={<Icons.PlusCircleOutlined />}>
                Yeni
              </StyledHeaderButton>
            </Link>
          )}
          <StyledHeaderButton $ghost={true} onClick={handleSelectAll} icon={<Icons.CheckCircleOutlined />}>
            Hamısını seç
          </StyledHeaderButton>
          <StyledHeaderButton $ghost={true} onClick={handleFetch} icon={<Icons.ReloadOutlined />}>
            Yenilə
          </StyledHeaderButton>
          <StyledHeaderButton $ghost={true} onClick={handleReset} icon={<Icons.ClearOutlined />}>
            Sıfırla
          </StyledHeaderButton>
        </StyledActionBar>
      )}
    </AppHeaderPortal>
  );
};
