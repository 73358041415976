import flatten from 'flat';
import moment from 'moment';
import { v1 as uuid } from 'uuid';
import {
  Appointment,
  AppointmentApi,
  AppointmentDetails,
  AppointmentDetailsGrouped,
  AppointmentFormData,
  AppointmentFormDataApi,
  AppointmentFormErrors,
  AppointmentFormErrorsApi,
  AppointmentQueue,
  AppointmentQueueApi,
  AppointmentServiceByDoctors,
} from '../types';
import { formDataFlat } from '../../../shared/utils/form-data-flat';
import { Constants } from '../../../shared/constants';

export class AppointmentMappers {
  public static detailsFromApi(appointmentDetails): AppointmentDetails {
    return {
      id: appointmentDetails.visit.id,
      customer: {
        id: appointmentDetails.client.id,
        fullName: appointmentDetails.client.client_name,
        gender: appointmentDetails.client.gender,
        phoneNumber: appointmentDetails.client.number,
        nationality: appointmentDetails.client.nationality,
        status: {
          id: appointmentDetails.client.state_id,
          name: appointmentDetails.client.state_name,
        },
        birthDate: appointmentDetails.client.birth_date,
        franchise: appointmentDetails.client.franchise,
        insuranceNumber: appointmentDetails.client.insurance_number,
        passport: { secret: appointmentDetails.client.pin_number },
        description: appointmentDetails.client.descr || '',
        createdAt: appointmentDetails.client.created_at,
      },
      services: appointmentDetails.services.map((service) => ({
        id: service.id,
        totalPrice: parseFloat(service.total_price),
        discount: parseFloat(service.discount),
        quantity: service.quantity,
        doctor: { id: service.doctor_id, name: service.doctor_name },
        reference: {
          id: service.service_id,
          name: service.service_name,
          price: parseFloat(service.service_price),
        },
      })),
      products: appointmentDetails.products.map((product) => ({
        id: product.id,
        quantity: product.quantity,
        discount: parseFloat(product.discount),
        totalPrice: parseFloat(product.total_price),
        doctor: { id: product.doctor_id, name: product.doctor_name },
        department: { id: product.warehouse_id, name: product.warehouse_name },
        executor: { id: product.user_id, name: product.user_name },
        reference: { id: product.id, name: product.product_name, price: product.sale_price},
        createdAt: product.created_at,
      })),
      transactions: appointmentDetails.transactions.map((item) => ({
        id: item.id,
        type: item.type,
        paymentTypeName: item.payment_type_name,
        amount: item.amount,
        restOfAmount: item.deficit,
        overPayment: item.overpayment,
        executor: { id: item.user_id, name: item.user_name },
        createdAt: item.created_at,
      })),
      sentBy:
        appointmentDetails.visit.doctor_id && appointmentDetails.visit.doctor_name
          ? {
              id: appointmentDetails.visit.doctor_id,
              name: appointmentDetails.visit.doctor_name,
              clinic:
                appointmentDetails.visit.partner_clinic_id && appointmentDetails.visit.clinic_name
                  ? {
                      id: appointmentDetails.visit.partner_clinic_id,
                      name: appointmentDetails.visit.clinic_name,
                    }
                  : null,
            }
          : null,
      payment: {
        totalPrice: parseFloat(appointmentDetails.visit.visit_total_price || '0'),
        discount: parseFloat(appointmentDetails.visit.visit_discount || '0'),
        price: parseFloat(appointmentDetails.visit.visit_price || '0'),
        clientAllPaid: parseFloat(appointmentDetails.payments.client_all_paid || '0'),
        clientAllUnpaid: parseFloat(appointmentDetails.payments.client_all_unpaid || '0'),
        deficit: parseFloat(appointmentDetails.payments.deficit || '0'),
        over: parseFloat(appointmentDetails.payments.over || '0'),
        visitRealPrice: parseFloat(appointmentDetails.payments.visit_total_price || '0'),
      },
      status: {
        id: appointmentDetails.visit.state_id,
        name: appointmentDetails.visit.state_name,
      },
      paid: !!appointmentDetails.visit.payed,
      qrCodeValue: appointmentDetails.visit.qr_code_data,
      insurance: appointmentDetails.visit.insurance_id
        ? {
            id: appointmentDetails.visit.insurance_id,
            name: appointmentDetails.visit.insurance_name,
            discount: appointmentDetails.visit.insurance_discount,
            transactions: {
              id: appointmentDetails.visit.insurance_transactions.id,
              insuranceId: appointmentDetails.visit.insurance_transactions.insurance_id,
              insurancePercent: parseFloat(appointmentDetails.visit.insurance_transactions.insurance_percent || '0'),
              amountWoDiscount: parseFloat(appointmentDetails.visit.insurance_transactions.amount_wodiscount || '0'),
              discount: parseFloat(appointmentDetails.visit.insurance_transactions.discount || '0'),
              amountDiscount: parseFloat(appointmentDetails.visit.insurance_transactions.amount_discount || '0'),
              amountRest: parseFloat(appointmentDetails.visit.insurance_transactions.amount_rest || '0'),
              franchise: parseFloat(appointmentDetails.visit.insurance_transactions.franchise || '0'),
              clientFranchise: parseFloat(appointmentDetails.visit.insurance_transactions.client_franchise || '0'),
              createdAt: appointmentDetails.visit.insurance_transactions.created_at,
            },
            details: {
              id: appointmentDetails.visit.insurance_info.id,
              name: appointmentDetails.visit.insurance_info.name,
              percent: parseFloat(appointmentDetails.visit.insurance_info.percent || '0'),
              description: appointmentDetails.visit.insurance_info.descr,
            },
          }
        : null,
      type: appointmentDetails.visit.visit_type === 0 ? 'outpatient' : 'sent',
      scheduledAt: appointmentDetails.visit.visit_date,
      createdAt: appointmentDetails.visit.created_at,
      description: appointmentDetails.visit.descr || '',
    };
  }

  public static fromApi(appointmentApi: AppointmentApi): Appointment {
    return {
      id: appointmentApi.id,
      customer: {
        id: appointmentApi.client_id,
        fullName: appointmentApi.client_name,
        gender: appointmentApi.gender,
        phoneNumber: appointmentApi.number,
        insuranceNumber: appointmentApi.insurance_number,
      },
      status: { id: appointmentApi.state_id, name: appointmentApi.state_name },
      services: [],
      products: [],
      type: appointmentApi.visit_type === 0 ? 'outpatient' : 'sent',
      description: appointmentApi.descr || '',
      visitPrice: appointmentApi.visit_price,
      scheduledAt: appointmentApi.visit_date,
      createdAt: appointmentApi.created_at,
    };
  }

  public static manyFromApi(appointmentsApi: AppointmentApi[]): Appointment[] {
    return appointmentsApi.map((item) => this.fromApi(item));
  }

  public static formDataToApi(appointmentFormData: AppointmentFormData): AppointmentFormDataApi {
    const mappedData = {
      visit_id: appointmentFormData.id,
      client_id: appointmentFormData.clientId,
      payed: appointmentFormData.paid,
      visit_type: appointmentFormData.type === 'outpatient' ? '0' : '1',
      doctor_id: appointmentFormData.doctorId,
      insurance_id: appointmentFormData.insuranceId,
      visit_date: appointmentFormData.scheduledAt?.format(Constants.DATE_TIME),
      services: appointmentFormData.services
        .filter(({ id }) => !!id)
        .map(({ id, doctorId, discount, customPrice, quantity }) => ({
          id,
          doctor_id: doctorId,
          discount: discount,
          service_price: customPrice,
          quantity,
        })),
      products: appointmentFormData.products
        .filter(({ id }) => !!id)
        .map(({ id, doctorId, quantity, discount }) => ({
          id,
          doctor_id: doctorId,
          discount,
          quantity,
        })),
      descr: appointmentFormData.description,
    };

    return formDataFlat(mappedData);
  }
  public static formErrorsFromApi(errors: AppointmentFormErrorsApi): AppointmentFormErrors {
    const unFlattenedErrors = flatten.unflatten(errors);

    return {
      id: unFlattenedErrors.visit_id?.join(', '),
      clientId: unFlattenedErrors.client_id?.join(', '),
      paid: unFlattenedErrors.payed?.join(', '),
      type: unFlattenedErrors.visit_type?.join(', '),
      doctorId: unFlattenedErrors.doctor_id?.join(', '),
      scheduledAt: unFlattenedErrors.visit_date?.join(', '),
      products:
        errors.products?.join(', ') ||
        unFlattenedErrors.products?.map(({ id, doctor_id, discount, quantity }) => ({ id, doctorId: doctor_id, discount, quantity })),
      services:
        errors.services?.join(', ') || unFlattenedErrors.services?.map(({ id, doctor_id, discount }) => ({ id, doctorId: doctor_id, discount })),
    };
  }
  public static formInitializer(appointment: AppointmentDetails): AppointmentFormData {
    return {
      id: appointment.id?.toString() || '0',
      clientId: appointment.customer.id.toString(),
      paid: appointment.paid,
      insuranceId: appointment.insurance?.id.toString() || '',
      type: appointment.type,
      doctorId: appointment.sentBy?.id.toString() || '',
      scheduledAt: appointment.scheduledAt ? moment(appointment.scheduledAt, Constants.DATE_TIME) : null,
      services: appointment.services.map((service) => ({
        uuid: uuid(),
        id: service.reference.id.toString(),
        doctorId: service.doctor.id.toString(),
        discount: service.discount.toString(),
        quantity: service.quantity.toString(),
        customPrice: service.reference.price.toString(),
      })),
      products: [],
      servicesTotalPrice: appointment.services.reduce((acc, service) => acc + service.totalPrice, 0).toString(),
      productsTotalPrice: '0',
      description: appointment.description,
    };
  }

  public static toPrint(appointment: AppointmentDetails, queue: AppointmentQueue[]): AppointmentDetailsGrouped {
    return {
      id: appointment.id,
      customer: {
        id: appointment.customer.id,
        name: appointment.customer.fullName,
        phoneNumber: appointment.customer.phoneNumber,
        birthDate: appointment.customer.birthDate,
        gender: appointment.customer.gender,
        insuranceNumber: appointment.customer.insuranceNumber,
      },
      qrCodeValue: appointment.qrCodeValue,
      sentBy: appointment.sentBy ? { name: appointment.sentBy.name, clinic: { name: appointment.sentBy.clinic?.name || '' } } : null,
      createdAt: appointment.createdAt,
      servicesByDoctors: appointment.services.reduce((acc: AppointmentServiceByDoctors[], serviceAndDoctor) => {
        const service = {
          id: serviceAndDoctor.reference.id,
          name: serviceAndDoctor.reference.name,
          discount: serviceAndDoctor.discount,
          totalPrice: serviceAndDoctor.totalPrice,
          referencePrice: serviceAndDoctor.reference.price,
        };
        const doctor = {
          id: serviceAndDoctor.doctor.id,
          name: serviceAndDoctor.doctor.name,
          queueNumber: queue.find(({ doctorId }) => doctorId === serviceAndDoctor.doctor.id)?.number || 0,
          totalPriceWithoutDiscount: service.referencePrice,
          totalDiscount: service.discount,
          totalPrice: service.totalPrice,
        };

        const foundDoctor = acc.find(({ id }) => id === doctor.id);
        const foundDoctorIndex = acc.findIndex(({ id }) => id === doctor.id);

        if (foundDoctor && foundDoctorIndex !== -1) {
          const copiedAcc = [...acc];
          copiedAcc[foundDoctorIndex] = {
            ...foundDoctor,
            totalPrice: foundDoctor.totalPrice + service.totalPrice,
            totalPriceWithoutDiscount: foundDoctor.totalPriceWithoutDiscount + service.referencePrice,
            totalDiscount: foundDoctor.totalDiscount + service.discount,
            services: [...foundDoctor.services, service],
          };
          return copiedAcc;
        } else {
          return [...acc, { ...doctor, services: [service] }];
        }
      }, []),
    };
  }

  public static queuesFromApi(queues: AppointmentQueueApi[]): AppointmentQueue[] {
    return queues.map((queue) => ({
      number: queue.queue_number,
      doctorId: queue.doctor_id,
    }));
  }
}
