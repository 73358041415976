import { urlMaker } from '../../../shared/utils/url-maker';
import { appendToFormData } from '../../../shared/utils/apply-to-form-data';
import { caller } from '../../../shared/utils/caller';
import { ApiResponse } from '../../../shared/utils/api-response';
import { formDataFlat } from '../../../shared/utils/form-data-flat';

export const updateEmergencyService = async (isEnabled: boolean): Promise<ApiResponse<200> | ApiResponse<400 | 500, string>> => {
  const url = urlMaker('/api/settings');
  const body = new FormData();

  appendToFormData(formDataFlat({ emergency_mode: isEnabled }), body);

  try {
    const response = await caller(url, { method: 'POST', body });

    if (response.ok) {
      return new ApiResponse(200, null, null);
    } else {
      return new ApiResponse(400, 'Əməliyyat aparıla bilmədi', null);
    }
  } catch (e) {
    return new ApiResponse(500, e.message, null);
  }
};
