import { useCallback, useContext, useMemo } from 'react';
import { CustomersTableContext } from '../context';
import { useSelection } from '../../../shared/modules/next-table/hooks/use-selection';
import { fetchCustomersTableDataUseCase } from '../use-cases/fetch-table-data';
import { nextTableResetAction, nextTableSelectAllAction, nextTableSetSelectedRowIdsAction } from '../../../shared/modules/next-table/context/actions';
import { CustomersService } from '../services';
import { message, Modal } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { nextTableFilterQueryMaker } from '../../../shared/modules/next-table/utils/query-maker';

export const useCustomersTableActions = () => {
  const history = useHistory();
  const location = useLocation();
  const { state, dispatch } = useContext(CustomersTableContext);
  const selection = useSelection(state.selectedRowIds);

  const handleFetch = useCallback(
    () =>
      fetchCustomersTableDataUseCase({
        filters: state.filters,
        sortBy: state.sortBy,
        pageIndex: state.pageIndex,
        pageSize: state.pageSize,
      })(dispatch),
    [dispatch, state.filters, state.pageIndex, state.pageSize, state.sortBy],
  );

  const handleReset = useCallback(() => dispatch(nextTableResetAction()), [dispatch]);
  const handleSelectAll = useCallback(() => dispatch(nextTableSelectAllAction()), [dispatch]);
  const handleResetSelection = useCallback(() => dispatch(nextTableSetSelectedRowIdsAction({})), [dispatch]);

  const handleAction = useCallback(
    async (actionType) => {
      const handler = async () => {
        message.loading({ key: 'message', content: 'Əməliyyat aparılır.' });
        const result = await CustomersService.actionByIds(selection, actionType);

        if (result.status === 200) {
          message.success({ key: 'message', content: 'Əməliyyat müvəffəqiyyətlə başa çatdı' });
          await handleFetch();
        } else {
          message.error({ key: 'message', content: result.data as string });
        }
      };

      Modal.confirm({
        title: 'Əməliyyatı etməyinizə əminsinizmi?',
        content: 'Əməliyyatı etdikdən sonra məlumatların geri qaytarılması mümkün olmayacaq.',
        onOk: handler,
      });
    },
    [handleFetch, selection],
  );

  const selectedItems = useMemo(() => state.data.filter((item) => selection.includes(item.id.toString())), [selection, state.data]);

  const goToCombine = useCallback(() => {
    history.push({ pathname: '/customers/combine', state: { background: location, customers: selectedItems } });
  }, [history, location, selectedItems]);

  const exportNumbers = useCallback(async () => {
    message.loading('Əməliyyat aparılır...', 0);
    const result = await CustomersService.exportNumbers(nextTableFilterQueryMaker(state.filters));
    message.destroy();

    if (result.status === 200) {
      message.success('Sənəd hazırlanır...');
      const objectURL = URL.createObjectURL(result.data.file);
      const a = document.createElement('a');
      a.href = objectURL;
      a.download = result.data.name;
      a.click();
    } else {
      message.error(result.data);
    }
  }, [state.filters]);

  return { goToCombine, selection, handleFetch, handleResetSelection, handleSelectAll, handleReset, handleAction, exportNumbers };
};
