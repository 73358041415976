import React from 'react';
import { Modal } from 'antd';

import { CustomerForm } from '../containers/form';
import { localURLMaker } from '../../../shared/utils/url-maker';
import { useCloseModal } from '../../../shared/hooks/use-close-modal';

export const CreateCustomerPage = () => {
  const { onClose } = useCloseModal();

  return (
    <Modal visible={true} width={576} footer={null} title='Yeni müştəri' onCancel={() => onClose('/customers')}>
      <CustomerForm type='create' redirectUrl={localURLMaker('/customers', {}, { reFetchCustomersTable: true })} />
    </Modal>
  );
};
