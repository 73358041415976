import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { ApiResponse } from '../../../shared/utils/api-response';
import { StatisticsMapper } from '../mappers';
import { StatisticsCounts } from '../types/counts';

export class StatisticsCountsService {
  public static async get(): Promise<ApiResponse<200, StatisticsCounts> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/dashboard/widgets');

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        const mappedData = StatisticsMapper.Counts.fromApi(data);

        return new ApiResponse(200, mappedData, null);
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi.', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }
}
