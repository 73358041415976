import React from 'react';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';

import { CashboxDetails } from '../containers/details';

export const CashboxDetailsPage = () => {
  const history = useHistory();

  return (
    <Modal visible={true} width={768} footer={null} onCancel={() => history.push('/cash-flow/cashboxes')} closable={false}>
      <CashboxDetails />
    </Modal>
  );
};
