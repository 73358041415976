import { MoneyBackFormData, MoneyBackFormDataApi } from '../types/money-back';

export class MoneyBackMappers {
  public static formDataToApi(values: MoneyBackFormData): MoneyBackFormDataApi {
    return {
      visit_id: values.appointmentId,
      cash: values.cash,
      bank: values.bank,
      bonus: values.bonus,
      deposit: values.deposit,
    };
  }
}
