import React, { FC, memo } from 'react';
import { Formik, FormikProps } from 'formik';
import { Button, Form, Spin } from 'antd';

import { FormAlert } from '../../../../shared/modules/form/styled';
import { TextField } from '../../../../shared/modules/form/fields/text';
import { FormOptions } from '../../../../shared/modules/form/types';
import { TextAreaField } from '../../../../shared/modules/form/fields/textarea';

import { useCashboxForm } from '../hooks/use-form';
import { CashboxFormData } from '../types';
import { useParams } from 'react-router';

type FormProps = FormikProps<CashboxFormData>;

const FormikComponent = memo<FormProps>(({ isSubmitting, handleSubmit, status }) => {
  const params = useParams<{ id?: string }>();

  return (
    <Form layout='vertical'>
      <FormAlert $visible={!!status} message={status?.message} type={status?.type} />

      <TextField name='cashboxName' item={{ label: 'Ad' }} />
      {!params?.id && <TextField format='decimal' name='amount' item={{ label: 'İlkin məbləğ' }} />}
      <TextAreaField name='description' item={{ label: 'Açıqlama' }} />
      <Button onClick={() => handleSubmit()} type='primary' loading={isSubmitting} block={true}>
        Təsdiqlə
      </Button>
    </Form>
  );
});

export const CashboxForm: FC<FormOptions> = (props) => {
  const { initialValues, ...form } = useCashboxForm(props);

  return (
    <Spin spinning={initialValues.loading}>
      <Formik {...form} enableReinitialize={true} initialValues={initialValues.data}>
        {(props) => <FormikComponent {...props} />}
      </Formik>
    </Spin>
  );
};
