import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

import { MeContext } from '../../../me/context/context';
import { CashboxesPage } from '../pages/main';
import { CreateCashboxPage } from '../pages/create';
import { UpdateCashboxPage } from '../pages/update';
import { CashboxDetailsPage } from '../pages/details';

export const CashboxesRouter = () => {
  const { can } = useContext(MeContext);

  return (
    <Route path='/cash-flow/cashboxes'>
      {can(109) && <Route path='/cash-flow/cashboxes/:id/details' component={CashboxDetailsPage} />}
      {can(110) && <Route path='/cash-flow/cashboxes/create' component={CreateCashboxPage} />}
      {can(111) && <Route path='/cash-flow/cashboxes/:id/update' component={UpdateCashboxPage} />}
      {can(109) && <Route path='/cash-flow/cashboxes' component={CashboxesPage} />}
    </Route>
  );
};
