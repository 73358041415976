import React, { lazy, Suspense, useContext } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { SuspenseSpin } from '../shared/styled/spin';

import { AppLayout } from '../modules/layout/containers';
import { IMeContext } from '../modules/me/context/types';
import { MeContext } from '../modules/me/context/context';
import StatisticsModule from '../modules/statistics';
import { CashRegisterStatusProvider } from '../modules/cash-register/context/status';
import { SimilarUsers } from '../modules/appointments/containers/found-users';
import { CombineCustomers } from '../modules/customers/containers/combine-customers';
import { CustomersModalRouter } from '../modules/customers/router';
import { CashboxesRouter } from '../modules/cash-flow/cash-flow-overview/router';

const MeModule = lazy(() => import('../modules/me'));
const StatusModule = lazy(() => import('../modules/status'));
const InsurancesModule = lazy(() => import('../modules/insurances'));
const ModelsModule = lazy(() => import('../modules/models'));
const UsersModule = lazy(() => import('../modules/users'));
const ClinicsModule = lazy(() => import('../modules/clinics'));
const WarehouseProductsModule = lazy(() => import('../modules/warehouse-products'));
const DoctorsModule = lazy(() => import('../modules/doctors'));
const CustomersModule = lazy(() => import('../modules/customers'));
const OperationsModule = lazy(() => import('../modules/operations'));
const GroupsModule = lazy(() => import('../modules/groups'));
const SubjectsModule = lazy(() => import('../modules/subjects/subjects.module'));
const AnalyzesModule = lazy(() => import('../modules/analyzes/analyzes.module'));
const DeviceLogsModule = lazy(() => import('../modules/device-logs/device-logs.module'));
const ProductsModule = lazy(() => import('../modules/products'));
const ServicesModule = lazy(() => import('../modules/services'));
const AppointmentsModule = lazy(() => import('../modules/appointments'));
const TransactionsModule = lazy(() => import('../modules/transactions'));
const CashRegisterModule = lazy(() => import('../modules/cash-register'));
const CashboxesModule = lazy(() => import('../modules/cash-flow/cashboxes'));
const CashCategoriesModule = lazy(() => import('../modules/cash-flow/categories'));
const CashflowTransactionsModule = lazy(() => import('../modules/cash-flow/transactions'));
const SmsLogsModule = lazy(() => import('../modules/sms-logs/sms-logs.module'));
const CashflowAnaliticsModule = lazy(() => import('../modules/cash-flow/analytics/pages/cash-flow-analtyics.page'));
const WelcomeModule = lazy(() => import('../modules/welcome/pages/welcome.page'));
const PurchaseDocumentsModule = lazy(() => import('../modules/purchase-documents'));
const WarehouseTransfersModule = lazy(() => import('../modules/warehouse-transfers'));
const ConsumptionsModule = lazy(() => import('../modules/consumptions'));

export const MainRouter = () => {
  const me = useContext<IMeContext>(MeContext);
  const location = useLocation<{ background?: any }>();

  if (me.state.user.loading) {
    return <SuspenseSpin />;
  }

  if (!!me.state.user.data) {
    return (
      <CashRegisterStatusProvider>
        <AppLayout>
          <Suspense fallback={<SuspenseSpin />}>
            <Switch location={location?.state?.background || location}>
              <Route path='/status' component={StatusModule} />
              <Route path='/insurances' component={InsurancesModule} />
              {!!me.state.user.data?.emergencyModeIsEnabled && <Route path='/welcome' component={WelcomeModule} />}
              <Route path='/models' component={ModelsModule} />
              <Route path='/users' component={UsersModule} />
              <Route path='/clinics' component={ClinicsModule} />
              <Route path='/doctors' component={DoctorsModule} />
              <Route path='/customers' component={CustomersModule} />
              <Route path='/operations' component={OperationsModule} />
              <Route path='/groups' component={GroupsModule} />
              <Route path='/analyzes' component={AnalyzesModule} />
              {me.can(123) && <Route path='/subjects' component={SubjectsModule} />}
              {me.can(122) && <Route path='/device-logs' component={DeviceLogsModule} />}
              <Route path='/products' component={ProductsModule} />
              <Route path='/services' component={ServicesModule} />
              <Route path='/appointments' component={AppointmentsModule} />
              <Route path='/transactions' component={TransactionsModule} />
              <Route path='/cash_register' component={CashRegisterModule} />
              <Route path='/cash-flow/cashboxes' component={CashboxesModule} />
              <Route path='/cash-flow/cash-categories' component={CashCategoriesModule} />
              <Route path='/cash-flow/transactions' component={CashflowTransactionsModule} />
              <Route path='/purchase-documents' component={PurchaseDocumentsModule} />
              <Route path='/warehouse-transfers' component={WarehouseTransfersModule} />
              <Route path='/warehouse-products' component={WarehouseProductsModule} />
              <Route path='/consumptions' component={ConsumptionsModule} />
              {me.can(119) && <Route path='/sms-logs' component={SmsLogsModule} />}
              {me.can(118) && <Route path='/cash-flow/analytics' component={CashflowAnaliticsModule} />}
              {!me.state.user.data?.emergencyModeIsEnabled && <Route path='/cash-flow' component={CashboxesRouter} />}
              {!me.state.user.data?.emergencyModeIsEnabled && <Route path='/statistics' component={StatisticsModule} />}
              <Redirect to={!!me.state.user.data?.emergencyModeIsEnabled ? '/welcome' : '/statistics'} />
            </Switch>

            <Switch>
              <Route path='/customers' component={CustomersModalRouter} />
              <Route path='/similar-users' component={SimilarUsers} />
              <Route path='/customers/combine' component={CombineCustomers} />
            </Switch>
          </Suspense>
        </AppLayout>
      </CashRegisterStatusProvider>
    );
  }

  return (
    <Suspense fallback={<SuspenseSpin />}>
      <Switch>
        <Route path='/' component={MeModule} />
      </Switch>
    </Suspense>
  );
};
