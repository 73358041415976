import { Col, Row, Typography } from 'antd';
import Icon from '@ant-design/icons';
import * as Icons from '../../../../shared/assets/icons';
import React, { useContext } from 'react';
import { CardLink, CardLinkIcon, CardLinkTitle } from '../../../../shared/styled/card-link';
import { MeContext } from '../../../me/context/context';

export const CashFlowOverviewPage = () => {
  const { can } = useContext(MeContext);

  return (
    <div>
      <Row gutter={[12, 12]}>
        <Col xs={24}>
          <Typography.Title level={3} style={{ marginBottom: 0 }}>
            Cashflow
          </Typography.Title>
        </Col>
        <Col xs={24} lg={12}>
          <CardLink to='/cash-flow/analytics'>
            <CardLinkIcon>
              <Icon component={Icons.BarChartOutlined} />
            </CardLinkIcon>
            <CardLinkTitle>Analitika</CardLinkTitle>
          </CardLink>
        </Col>
        {can(109) && (
          <Col xs={24} lg={12}>
            <CardLink to='/cash-flow/cashboxes'>
              <CardLinkIcon>
                <Icon component={Icons.CashRegisterOutlined} />
                <Icons.CashRegisterOutlined />
              </CardLinkIcon>
              <CardLinkTitle>Kassalar</CardLinkTitle>
            </CardLink>
          </Col>
        )}
        {can(113) && (
          <Col xs={24} lg={12}>
            <CardLink to='/cash-flow/cash-categories'>
              <CardLinkIcon>
                <Icon component={Icons.OperationsOutlined} />
              </CardLinkIcon>
              <CardLinkTitle>Əməliyyat kateqoriyaları</CardLinkTitle>
            </CardLink>
          </Col>
        )}
        {can(113) && (
          <Col xs={24} lg={12}>
            <CardLink to='/cash-flow/transactions'>
              <CardLinkIcon>
                <Icon component={Icons.TransferOutlined} />
              </CardLinkIcon>
              <CardLinkTitle>Tranzaksiyalar</CardLinkTitle>
            </CardLink>
          </Col>
        )}
      </Row>
    </div>
  );
};
