import React, { useContext } from 'react';
import * as Icons from '@ant-design/icons';
import { Button, Space } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { StyledHeaderButton } from '../../layout/styled/header';
import { AppHeaderPortal } from '../../layout/components/header-portal';

import { StyledActionBar } from '../../../shared/styled/action-bar';
import { useCustomersTableActions } from '../hooks/use-table-actions';
import { MeContext } from '../../me/context/context';

export const CustomersTableActions = () => {
  const location = useLocation();
  const { selection, handleFetch, handleResetSelection, handleSelectAll, handleReset, handleAction, goToCombine, exportNumbers } =
    useCustomersTableActions();
  const { can } = useContext(MeContext);

  return (
    <AppHeaderPortal>
      {!!selection.length && (
        <StyledActionBar $visible={!!selection.length} $flex={true}>
          <StyledActionBar.Selection>
            <Icons.CloseCircleTwoTone role='icon' onClick={handleResetSelection} />
            <span>{selection.length} sətir seçilib</span>
          </StyledActionBar.Selection>
          <Space size={8}>
            <Button icon={<Icons.MergeCellsOutlined />} disabled={selection.length < 2} onClick={goToCombine}>
              Birləşdir
            </Button>
            {can(37) && (
              <Button type='primary' danger={true} icon={<Icons.DeleteOutlined />} onClick={() => handleAction('remove')}>
                Sil
              </Button>
            )}
          </Space>
        </StyledActionBar>
      )}
      {!selection.length && (
        <StyledActionBar $flex={true} $visible={!selection.length}>
          <div>
            {can(35) && (
              <Link to={{ pathname: '/customers/create', state: { background: location } }}>
                <StyledHeaderButton $ghost={true} icon={<Icons.PlusCircleOutlined />}>
                  Yeni
                </StyledHeaderButton>
              </Link>
            )}
            <StyledHeaderButton $ghost={true} onClick={handleSelectAll} icon={<Icons.CheckCircleOutlined />}>
              Hamısını seç
            </StyledHeaderButton>
            <StyledHeaderButton $ghost={true} onClick={handleFetch} icon={<Icons.ReloadOutlined />}>
              Yenilə
            </StyledHeaderButton>
            <StyledHeaderButton $ghost={true} onClick={handleReset} icon={<Icons.ClearOutlined />}>
              Sıfırla
            </StyledHeaderButton>
          </div>
          <div>
            <StyledHeaderButton onClick={exportNumbers} icon={<Icons.FileExcelOutlined />}>
              Excel export
            </StyledHeaderButton>
          </div>
        </StyledActionBar>
      )}
    </AppHeaderPortal>
  );
};
