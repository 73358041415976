import { NextTableFetchParams } from '../types';

export const nextTableQueryMaker = (params: NextTableFetchParams) => {
  const filters = nextTableFilterQueryMaker(params.filters);
  const sort = params.sortBy.reduce((acc, { id, desc }) => ({ ...acc, sort_column: id, sort_order: desc ? 'desc' : 'asc' }), {});
  const page = params.pageIndex + 1;

  return { ...filters, ...sort, page, per_page: params.pageSize };
};

export const nextTableFilterQueryMaker = (filters: NextTableFetchParams['filters']) => {
  return filters.reduce((acc, { id, value }) => ({ ...acc, [id]: value }), {});
};
