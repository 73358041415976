import React from 'react';
import { PageContent } from '../../../shared/styled/page-content';
import { CustomersTable } from '../containers/table';
import { CustomersTableActions } from '../containers/table-actions';
import { NextTableProvider } from '../../../shared/modules/next-table/context/provider';
import { fetchCustomersTableDataUseCase } from '../use-cases/fetch-table-data';
import { CustomersTableContext } from '../context';

export const CustomersPage = () => {
  return (
    <NextTableProvider
      defaultState={{ hiddenColumns: ['pin_number_exists'] }}
      onFetch={fetchCustomersTableDataUseCase}
      context={CustomersTableContext}
    >
      <PageContent $contain={true}>
        <CustomersTableActions />
        <CustomersTable />
      </PageContent>
    </NextTableProvider>
  );
};
