import { useContext } from 'react';
import { CashboxesTableContext } from '../context';
import { useSelection } from '../../../../shared/modules/next-table/hooks/use-selection';

export const useCashboxesTableActions = () => {
  const { state, handleFetch, handleReset, handleSelectAll, handleResetSelection } = useContext(CashboxesTableContext);
  const selection = useSelection(state.selectedRowIds);

  return { selection, handleFetch, handleResetSelection, handleSelectAll, handleReset };
};
