import React, { FC } from 'react';
import { NextTable } from '../../../../shared/modules/next-table/containers';
import { CashboxesTableContext } from '../context';
import { useCashboxesTable } from '../hooks/use-table';

export const CashboxesTable: FC<{}> = () => {
  const { columns, getRowProps } = useCashboxesTable();

  return <NextTable context={CashboxesTableContext} columns={columns} getRowProps={getRowProps} />;
};
