import React, { FC } from 'react';
import { NextTable } from '../../../shared/modules/next-table/containers';
import { AppointmentsTableContext } from '../context';
import { useAppointmentsTable } from '../hooks/use-table';

export const AppointmentsTable: FC<{ limited?: boolean }> = ({ limited }) => {
  const { columns, getRowProps } = useAppointmentsTable({ limited });

  return <NextTable context={AppointmentsTableContext} columns={columns} getRowProps={getRowProps} />;
};
