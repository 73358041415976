import React, { useCallback } from 'react';
import { Button, Card, Col, Descriptions, Modal, Row, Space } from 'antd';
import { useHistory, useLocation, generatePath } from 'react-router-dom';
import { Customer } from '../../customers/types';

export const SimilarUsers = () => {
  const location = useLocation<{ background?: any; customers?: Customer[] }>();
  const { push } = useHistory();

  const close = useCallback(() => {
    push(location.state?.background);
  }, [push, location.state?.background]);

  const goToDetails = useCallback(
    (id) => {
      push({
        pathname: generatePath('/customers/:id/details', { id }),
        state: { background: location.state?.background, previousLocation: location },
      });
    },
    [push, location],
  );

  const chooseCustomer = useCallback(
    (id) => {
      push({
        pathname: generatePath('/appointments/create/:id', { id }),
      });
    },
    [push],
  );

  if (!location.state?.customers || !location.state?.background) {
    return null;
  }

  return (
    <Modal visible={true} width={768} footer={null} onCancel={close} title='Oxşar müştərilər'>
      <Row gutter={[0, 16]}>
        {location.state?.customers.map((customer) => (
          <Col key={customer.id} span={24}>
            <Card
              title={`#${customer.id} - ${customer.firstname} ${customer.lastname} ${customer.patronymic}`}
              size='small'
              bodyStyle={{ padding: 0 }}
              extra={
                <Space size={8}>
                  <Button onClick={() => goToDetails(customer.id)}>Ətraflı</Button>
                  <Button onClick={() => chooseCustomer(customer.id)}>Seç</Button>
                </Space>
              }
            >
              <Descriptions column={2} bordered={true} size='small'>
                <Descriptions.Item label='Email'>{customer.email}</Descriptions.Item>
                <Descriptions.Item label='Telefon'>{customer.phoneNumber}</Descriptions.Item>
                <Descriptions.Item label='FİN Kod'>{customer.passport.secret}</Descriptions.Item>
                <Descriptions.Item label='Cinsi'>{customer.gender && (customer.gender === 'female' ? 'Qadın' : 'Kişi')}</Descriptions.Item>
                <Descriptions.Item label='Doğum tarixi'>{customer.createdAt}</Descriptions.Item>
                <Descriptions.Item label='Milliyəti'>{customer.nationality}</Descriptions.Item>
                <Descriptions.Item label='Yaradılma tarixi'>{customer.createdAt}</Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        ))}
      </Row>
    </Modal>
  );
};
