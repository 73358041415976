import { caller } from '../../../shared/utils/caller';
import { urlMaker } from '../../../shared/utils/url-maker';
import { ApiResponse } from '../../../shared/utils/api-response';
import { appendToFormData } from '../../../shared/utils/apply-to-form-data';
import { NextTableMetaResponse } from '../../../shared/modules/next-table/types';
import { nextTableMetaMapper } from '../../../shared/modules/next-table/utils/meta-mapper';

import { CashRegisterMapper } from '../mappers';
import { Withdraw } from '../types/withdraw';

export class CashRegisterWithdrawsService {
  public static async getAll(
    query?: Record<string, any>,
  ): Promise<ApiResponse<200, Withdraw[], NextTableMetaResponse> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/device/withdraw_logs', query);

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data, ...meta } = await response.json();
        return new ApiResponse(200, CashRegisterMapper.Withdraws.manyFromApi(data), nextTableMetaMapper(meta));
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }

  public static async request(amount: number | string): Promise<ApiResponse<200> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/device/withDraw');
    const body = new FormData();
    appendToFormData({ sum: amount, currency: 'AZN' }, body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResponse(200, null, null);
      } else if (response.status === 400 || response.status === 422) {
        const { errors } = await response.json();
        const mappedErrors = Object.values<string[]>(errors).flat().join(', ');

        return new ApiResponse(400, mappedErrors, null);
      } else {
        return new ApiResponse(400, 'Əməliyyat aparıla bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }
}
