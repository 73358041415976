import React from 'react';
import { PageContent } from '../../../../shared/styled/page-content';
import { CashboxesTable } from '../containers/table';
import { CashboxTableActions } from '../containers/table-actions';

export const CashboxesPage = () => {
  return (
    <PageContent $contain={true}>
      <CashboxTableActions />
      <CashboxesTable />
    </PageContent>
  );
};
