import React, { Fragment, useContext, useMemo, useState } from 'react';
import { Button, Modal, Select, Table, Tag } from 'antd';
import * as Icons from '@ant-design/icons';
import { useCurrentCashRegisterForm } from '../hooks/use-current-device';
import { CashRegisterStatusContext } from '../context/status';

const CashRegisterSelection = ({ onSubmitSucceed }) => {
  const { devices, currentDeviceId, handleDeviceChange, handleSubmit, submitting } = useCurrentCashRegisterForm({ onSubmitSucceed });

  return (
    <div>
      <Select
        value={currentDeviceId}
        onChange={handleDeviceChange}
        loading={devices.loading}
        disabled={devices.loading}
        style={{ width: '100%', marginBottom: 24 }}
      >
        {devices.data.map((device) => (
          <Select.Option key={device.id} value={device.id.toString()}>
            {device.name}
          </Select.Option>
        ))}
      </Select>
      <Table dataSource={devices.data} pagination={{ hideOnSinglePage: true }} style={{ marginBottom: 24 }} rowKey='id' bordered={true} size='small'>
        <Table.Column key='id' dataIndex='id' title='Kod' />
        <Table.Column key='name' dataIndex='name' title='Obyekt' />
        <Table.Column key='connected' dataIndex='connected' title='Status' render={(value) => (value ? 'Qoşuludur' : 'Qoşulu deyil')} />
        <Table.Column key='createdAt' dataIndex='createdAt' title='Yaradılıb' />
      </Table>
      <Button onClick={handleSubmit} loading={submitting} block={true} type='primary'>
        Təsdiqlə
      </Button>
    </div>
  );
};

export const CurrentCashRegister = () => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const status = useContext(CashRegisterStatusContext);

  const statusColor = useMemo<string>(() => {
    if (status.loading) {
      return 'processing';
    }

    if (!!status.error) {
      return 'warning';
    }

    return status.data?.connected ? 'success' : 'error';
  }, [status.data, status.loading, status.error]);

  return (
    <Fragment>
      <Tag
        onMouseEnter={() => status.reFetch()}
        style={{ cursor: 'pointer' }}
        color={statusColor}
        onClick={() => setModalVisible(true)}
        icon={<Icons.PrinterOutlined />}
      >
        {(() => {
          if (status.loading) {
            return 'Yüklənir';
          }

          if (status.error === 'no_chosen_device') {
            return 'Obyekt seç';
          }

          if (status.error) {
            return 'Qoşulu deyil';
          }

          return status.data?.name || 'Xəta';
        })()}
      </Tag>
      <Modal destroyOnClose={true} footer={null} title='Kassa seçin' visible={modalVisible} onCancel={() => setModalVisible(false)}>
        <CashRegisterSelection onSubmitSucceed={() => setModalVisible(false)} />
      </Modal>
    </Fragment>
  );
};
