import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row, DatePicker } from 'antd';
import moment, { Moment } from 'moment';

import { StatisticsCounts } from '../containers/counts';
import { StatisticsByDates } from '../containers/by-dates';
import { StatisticsAppointmentsByModels } from '../containers/appointments-by-models';

const { RangePicker } = DatePicker;

export const StatisticsPage = () => {
  const [startDate, setStartDate] = useState<Moment>(moment().startOf('month'));
  const [endDate, setEndDate] = useState<Moment>(moment().endOf('month'));

  const date = useMemo<[Moment, Moment]>(() => [startDate, endDate], [startDate, endDate]);

  const handleChangeDate = useCallback<any>((value: [Moment, Moment] | null) => {
    if (value) {
      setStartDate(value[0]);
      setEndDate(value[1]);
    } else {
      setStartDate(moment().startOf('month'));
      setEndDate(moment().endOf('month'));
    }
  }, []);

  return (
    <Row gutter={[12, 12]}>
      <Col xs={24}>
        <StatisticsCounts />
      </Col>
      <Col span={24}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <RangePicker size='large' value={date} onChange={handleChangeDate} />
        </div>
      </Col>
      <Col xs={24}>
        <StatisticsByDates startDate={startDate} endDate={endDate} />
      </Col>
      <Col xs={24}>
        <StatisticsAppointmentsByModels startDate={startDate} endDate={endDate} />
      </Col>
    </Row>
  );
};
