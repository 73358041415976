import { ApiResponse } from '../../../shared/utils/api-response';
import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';

import { StatisticsByDates } from '../types/by-dates';
import { StatisticsMapper } from '../mappers';

export class StatisticsByDatesService {
  public static async get({ startDate, endDate }): Promise<ApiResponse<200, StatisticsByDates> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/dashboard/charts', { start_date: startDate, end_date: endDate });

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();

        return new ApiResponse(200, StatisticsMapper.ByDates.fromApi(data), null);
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }
}
