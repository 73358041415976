import { StatisticsAppointmentsByModels, StatisticsAppointmentsByModelsApi } from '../types/appointments';
import { generateRandomColor } from '../../../shared/utils/random-color';

export class StatisticsAppointments {
  public static byModelsFromApi(data: StatisticsAppointmentsByModelsApi): StatisticsAppointmentsByModels {
    return {
      services: data.by_service.map(({ count, service_name }) => ({ total: count, name: service_name, color: '#' + generateRandomColor() })),
      doctors: data.by_doctor.map(({ count, doctor_name }) => ({ total: count, name: doctor_name, color: '#' + generateRandomColor() })),
    };
  }
}
