import { useCallback, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import { CustomerFormData } from '../types';
import { CustomersService } from '../services';
import { useQuery } from '../../../shared/modules/use-query/hooks';
import { UseQueryOptions } from '../../../shared/modules/use-query/types';
import { FormOptions } from '../../../shared/modules/form/types';
import { CustomerMappers } from '../mappers';

export const useCustomerForm = ({ type = 'create', redirectUrl }: FormOptions) => {
  const params = useParams<{ id?: string }>();
  const history = useHistory();
  const baseInitialValues = useMemo<CustomerFormData>(
    () => ({
      firstname: '',
      lastname: '',
      patronymic: '',
      nationality: '',
      phoneNumber: '',
      email: '',
      passportSecret: '',
      gender: null,
      insuranceNumber: '',
      franchise: '',
      birthDate: null,
      statusId: '5',
      description: '',
    }),
    [],
  );

  const handleQuery = useCallback(async () => {
    const result = await CustomersService.getOneById(params.id as string);

    if (result.status === 200) {
      return CustomerMappers.formInitializer(result.data);
    } else {
      return baseInitialValues;
    }
  }, [baseInitialValues, params.id]);

  const queryOptions = useMemo<UseQueryOptions>(
    () => ({
      initialData: baseInitialValues,
      enabled: !!params.id,
    }),
    [baseInitialValues, params.id],
  );

  const initialValues = useQuery(handleQuery, queryOptions);

  const onSubmit = useCallback(
    async (values: CustomerFormData, helpers: FormikHelpers<CustomerFormData>) => {
      helpers.setStatus(null);
      const result = await CustomersService.createOrUpdate(values, type);

      if (result.status === 200) {
        if (redirectUrl) {
          history.push(redirectUrl);
        } else {
          helpers.resetForm();
        }
        message.success('Məlumatlar müvəffəqiyyətlə yeniləndi.');
      } else if (result.status === 422) {
        helpers.setErrors(result.data);
      } else {
        helpers.setStatus({ type: 'error', message: result.data });
      }
    },
    [history, redirectUrl, type],
  );

  return { initialValues, onSubmit };
};
