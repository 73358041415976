import React, { useMemo } from 'react';
import { Card, Col, Row } from 'antd';
import { Line } from 'react-chartjs-2';
import { useStatisticsByDates } from '../hooks/use-by-dates';

export const StatisticsByDates = ({ startDate, endDate }) => {
  const { loading, data } = useStatisticsByDates({ startDate, endDate });

  const hideLabel = useMemo(() => ({ scales: { xAxes: [{ ticks: { display: false } }] } }), []);

  return (
    <Row gutter={[12, 12]}>
      <Col span={24} lg={12} xxl={8}>
        <Card loading={loading} size='small' title='Qəbullar günlər üzrə'>
          <Line
            options={(data?.appointments.byDays.length || 0) > 30 ? hideLabel : undefined}
            data={{
              labels: data?.appointments.byDays.map(({ date }) => date),
              datasets: [
                {
                  label: 'Ümumi say',
                  fill: false,
                  lineTension: 0.1,
                  backgroundColor: 'rgba(75,192,192,0.4)',
                  borderColor: 'rgba(75,192,192,1)',
                  borderCapStyle: 'round',
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: 'miter',
                  pointBorderColor: 'rgba(75,192,192,1)',
                  pointBackgroundColor: '#fff',
                  pointBorderWidth: 1,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                  pointHoverBorderColor: 'rgba(220,220,220,1)',
                  pointHoverBorderWidth: 2,
                  pointRadius: 1,
                  pointHitRadius: 10,
                  data: data?.appointments.byDays.map(({ total }) => total),
                },
              ],
            }}
          />
        </Card>
      </Col>
      <Col span={24} lg={12} xxl={8}>
        <Card loading={loading} size='small' title='Qəbullar aylar üzrə'>
          <Line
            options={(data?.appointments.byMonths.length || 0) > 30 ? hideLabel : undefined}
            data={{
              labels: data?.appointments.byMonths.map(({ date }) => date),
              datasets: [
                {
                  label: 'Ümumi say',
                  fill: false,
                  lineTension: 0.1,
                  backgroundColor: 'rgba(75,192,192,0.4)',
                  borderColor: 'rgba(75,192,192,1)',
                  borderCapStyle: 'round',
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: 'miter',
                  pointBorderColor: 'rgba(75,192,192,1)',
                  pointBackgroundColor: '#fff',
                  pointBorderWidth: 1,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                  pointHoverBorderColor: 'rgba(220,220,220,1)',
                  pointHoverBorderWidth: 2,
                  pointRadius: 1,
                  pointHitRadius: 10,
                  data: data?.appointments.byMonths.map(({ total }) => total),
                },
              ],
            }}
          />
        </Card>
      </Col>
      <Col span={24} lg={12} xxl={8}>
        <Card loading={loading} size='small' title='Müştəri qeydiyyatı günlər üzrə'>
          <Line
            options={(data?.customers.byDays.length || 0) > 30 ? hideLabel : undefined}
            data={{
              labels: data?.customers.byDays.map(({ date }) => date),
              datasets: [
                {
                  label: 'Ümumi say',
                  fill: false,
                  lineTension: 0.1,
                  backgroundColor: '#ed0f51',
                  borderColor: '#ed0f51',
                  borderCapStyle: 'round',
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: 'miter',
                  pointBorderColor: '#ed0f51',
                  pointBackgroundColor: '#ffffff',
                  pointBorderWidth: 1,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: '#ed0f51',
                  pointHoverBorderColor: '#ed0f51',
                  pointHoverBorderWidth: 2,
                  pointRadius: 1,
                  pointHitRadius: 10,
                  data: data?.customers.byDays.map(({ total }) => total),
                },
              ],
            }}
          />
        </Card>
      </Col>
      <Col span={24} lg={12} xxl={8}>
        <Card loading={loading} size='small' title='Müştəri qeydiyyatı aylar üzrə'>
          <Line
            options={(data?.customers.byMonths.length || 0) > 30 ? hideLabel : undefined}
            data={{
              labels: data?.customers.byMonths.map(({ date }) => date),
              datasets: [
                {
                  label: 'Ümumi say',
                  fill: false,
                  lineTension: 0.1,
                  backgroundColor: '#ed0f51',
                  borderColor: '#ed0f51',
                  borderCapStyle: 'round',
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: 'miter',
                  pointBorderColor: '#ed0f51',
                  pointBackgroundColor: '#ffffff',
                  pointBorderWidth: 1,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: '#ed0f51',
                  pointHoverBorderColor: '#ed0f51',
                  pointHoverBorderWidth: 2,
                  pointRadius: 1,
                  pointHitRadius: 10,
                  data: data?.customers.byMonths.map(({ total }) => total),
                },
              ],
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};
