import React, { Context, FC, useCallback, useImperativeHandle, useMemo, useReducer, useRef } from 'react';

import { nextTableReducer, nextTableState } from './reducer';
import { NextTableContext, NextTableState } from '../types';
import {
  nextTableResetAction,
  nextTableSelectAllAction,
  nextTableSetFiltersAction,
  nextTableSetPageIndexAction,
  nextTableSetPageSizeAction,
  nextTableSetSelectedRowIdsAction,
  nextTableSetSortByAction,
} from './actions';
import { useDebounceEffect } from '../../../hooks/use-debounce-effect';

export const NextTableProvider: FC<{ context: Context<NextTableContext>; onFetch: Function; defaultState?: Partial<NextTableState> }> = ({
  children,
  context: Context,
  onFetch,
  defaultState,
}) => {
  const [state, dispatch] = useReducer(nextTableReducer, { ...nextTableState, ...defaultState });
  const stateRef = useRef<NextTableState>(state);

  useImperativeHandle(stateRef, () => state, [state]);

  const handleChangeFilters = useCallback(
    (filters) => {
      dispatch(nextTableSetFiltersAction(filters));
    },
    [dispatch],
  );

  const handleChangeSortBy = useCallback(
    (sortBy) => {
      dispatch(nextTableSetSortByAction(sortBy));
    },
    [dispatch],
  );

  const handleChangePageIndex = useCallback(
    (pageIndex) => {
      dispatch(nextTableSetPageIndexAction(pageIndex));
    },
    [dispatch],
  );

  const handleChangePageSize = useCallback(
    (pageSize) => {
      dispatch(nextTableSetPageSizeAction(pageSize));
    },
    [dispatch],
  );

  const handleChangeSelectedRowIds = useCallback(
    (selectedRowIds) => {
      dispatch(nextTableSetSelectedRowIdsAction(selectedRowIds));
    },
    [dispatch],
  );

  const handleFetch = useCallback(() => {
    onFetch({
      filters: stateRef.current.filters,
      sortBy: stateRef.current.sortBy,
      pageIndex: stateRef.current.pageIndex,
      pageSize: stateRef.current.pageSize,
    })(dispatch);
  }, [onFetch]);

  const handleReset = useCallback(() => dispatch(nextTableResetAction()), [dispatch]);
  const handleSelectAll = useCallback(() => dispatch(nextTableSelectAllAction()), [dispatch]);
  const handleResetSelection = useCallback(() => dispatch(nextTableSetSelectedRowIdsAction({})), [dispatch]);

  useDebounceEffect(
    useCallback(() => {
      onFetch({ filters: state.filters, sortBy: state.sortBy, pageIndex: state.pageIndex, pageSize: state.pageSize })(dispatch);
    }, [onFetch, state.filters, state.pageIndex, state.pageSize, state.sortBy]),
    300,
  );

  const value = useMemo(
    () => ({
      state,
      dispatch,
      handleChangeFilters,
      handleChangeSortBy,
      handleChangePageIndex,
      handleChangePageSize,
      handleChangeSelectedRowIds,
      handleFetch,
      handleReset,
      handleSelectAll,
      handleResetSelection,
      stateRef,
    }),
    [
      state,
      handleChangeFilters,
      handleChangeSortBy,
      handleChangePageIndex,
      handleChangePageSize,
      handleChangeSelectedRowIds,
      handleFetch,
      handleReset,
      handleSelectAll,
      handleResetSelection,
    ],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
