import { useHistory } from 'react-router-dom';
import { MouseEvent, useCallback, useMemo } from 'react';

export const useCloseModal = () => {
  const history = useHistory<{ background?: any; previousLocation?: any }>();

  const canGoBack = useMemo(() => !!history.location.state?.previousLocation, [history.location.state?.previousLocation]);

  const onClose = useCallback(
    (fallbackUrl: string) => {
      if (history.location.state?.background) {
        history.push(history.location.state?.background);
      } else {
        history.push(fallbackUrl);
      }
    },
    [history],
  );

  const onBack = useCallback(
    (event?: MouseEvent) => {
      event?.stopPropagation();
      if (history.location.state?.previousLocation) {
        history.push(history.location.state?.previousLocation);
      }
    },
    [history],
  );

  return { onClose, onBack, canGoBack };
};
