import { Dispatch } from 'react';
import {
  nextTableFetchDataFailedAction,
  nextTableFetchDataStartedAction,
  nextTableFetchDataSucceedAction,
} from '../../../shared/modules/next-table/context/actions';
import { NextTableActions } from '../../../shared/modules/next-table/context/action-types';
import { nextTableQueryMaker } from '../../../shared/modules/next-table/utils/query-maker';

import { AppointmentsService } from '../services';
import { NextTableFetchParams } from '../../../shared/modules/next-table/types';

export const fetchAppointmentsTableDataUseCase = (params: NextTableFetchParams) => async (dispatch: Dispatch<NextTableActions>) => {
  dispatch(nextTableFetchDataStartedAction());
  const result = await AppointmentsService.getAll(nextTableQueryMaker(params));
  if (result.status === 200) {
    dispatch(nextTableFetchDataSucceedAction({ ...result.meta, data: result.data }));
  } else {
    dispatch(nextTableFetchDataFailedAction(result.data as string));
  }
};
