import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { LayoutContext } from '../context';

export const useSidebar = () => {
  const { state } = useContext(LayoutContext);
  const location = useLocation();

  return {
    isOpen: state.sidebar.isOpen,
    activeKey: location.pathname,
  };
};
