import { CashRegisterLastShifts, CashRegisterLastShiftsApi, CashRegisterShift, CashRegisterShiftApi } from '../types/shift';

export class CashRegisterShiftsMapper {
  public static oneFromApi(shift: CashRegisterShiftApi): CashRegisterShift {
    return {
      id: shift.id,
      shiftId: shift.shift_id,
      user: { id: 0, name: shift.user_name },
      device: { id: shift.device_id, name: shift.device_name },
      operation: shift.operation === 'openShift' ? 'opened' : 'closed',
      amount: parseFloat(shift.sum),
      createdAt: shift.created_at,
    };
  }

  public static manyFromApi(shifts: CashRegisterShiftApi[]): CashRegisterShift[] {
    return shifts.map((shift) => this.oneFromApi(shift));
  }

  public static lastsFromApi(lastShifts: CashRegisterLastShiftsApi): CashRegisterLastShifts {
    return {
      id: lastShifts.shift.id,
      opened: lastShifts.open
        ? {
            user: { id: lastShifts.open.user_id, name: lastShifts.open.user_name },
            createdAt: lastShifts.open.created_at,
          }
        : null,
      closed: lastShifts.close
        ? {
            user: { id: lastShifts.close.user_id, name: lastShifts.close.user_name },
            createdAt: lastShifts.close.created_at,
          }
        : null,
    };
  }

  public static statusFromApi(status: { shift_open: boolean }): boolean {
    return status.shift_open;
  }
}
