import { Check, CheckApi } from '../types/check';

export class CashRegisterChecksMapper {
  public static oneFromApi(checkApi: CheckApi): Check {
    return {
      id: checkApi.id,
      customer: {
        name: checkApi.clientName,
      },
      cashRegister: {
        id: checkApi.device_id,
        name: checkApi.device_name,
      },
      cashier: {
        name: checkApi.cashierName,
      },
      payment: {
        cash: !!checkApi.cashPayment ? parseFloat(checkApi.cashPayment) : 0,
        card: !!checkApi.cardPayment ? parseFloat(checkApi.cardPayment) : 0,
        bonus: !!checkApi.bonusPayment ? parseFloat(checkApi.bonusPayment) : 0,
        deposit: !!checkApi.depositPayment ? parseFloat(checkApi.depositPayment) : 0,
      },
      document: {
        id: checkApi.document_id,
      },
      shortDocument: {
        id: checkApi.short_document_id,
      },
      shift: { id: checkApi.shift_id },
      changed: !!checkApi.changed,
      refunded: !!checkApi.moneyback,
      status: {
        id: checkApi.state_id,
        name: checkApi.state_name,
      },
      createdAt: checkApi.created_at,
    };
  }

  public static manyFromApi(raw: CheckApi[]): Check[] {
    return raw.map((item) => this.oneFromApi(item));
  }
}
