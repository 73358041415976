import React, { ChangeEvent, FC, memo, useCallback, useMemo } from 'react';
import { Form, Input } from 'antd';
import { InputProps } from 'antd/es/input';
import { FormItemProps } from 'antd/es/form';
import { FieldConfig, FieldInputProps, FieldMetaProps, useField } from 'formik';
import { twoLevelShallowEqualObject } from '../../../utils/two-level-shallow-equal-object';
import { InputFormatters } from '../../../utils/input-formatters';

export type MemoizedTextFieldProps = {
  item?: FormItemProps;
  input?: InputProps;
  field: FieldInputProps<any>;
  meta: FieldMetaProps<any>;
};

export type TextFieldProps = FieldConfig & {
  item?: FormItemProps;
  input?: InputProps;
  format?: 'text' | 'integer' | 'decimal';
  writable?: (event: ChangeEvent<HTMLInputElement>) => boolean;
};

const MemoizedTextField = memo<MemoizedTextFieldProps>(({ item, input, field, meta }) => {
  return (
    <Form.Item {...item} validateStatus={meta.touched && !!meta.error ? 'error' : undefined} help={meta.error}>
      <Input {...field} {...input} />
    </Form.Item>
  );
}, twoLevelShallowEqualObject);

export const TextField: FC<TextFieldProps> = ({ item, input, writable, format, ...props }) => {
  const [{ onChange, ...field }, meta] = useField(props);

  const newOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (writable === undefined || writable?.(event)) {
        switch (format) {
          case 'decimal':
            return onChange(InputFormatters.decimal(event));
          case 'integer':
            return onChange(InputFormatters.integer(event));
          default:
            return onChange(event);
        }
      }
    },
    [format, onChange, writable],
  );

  const newField = useMemo(() => ({ onChange: newOnChange, ...field }), [field, newOnChange]);

  return <MemoizedTextField field={newField} meta={meta} item={item} input={input} />;
};
