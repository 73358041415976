import { useCallback, useMemo } from 'react';

import { useQuery } from '../../../shared/modules/use-query/hooks';
import { Constants } from '../../../shared/constants';
import { StatisticsService } from '../services';
import { StatisticsByDates } from '../types/by-dates';

export const useStatisticsByDates = ({ startDate, endDate }) => {
  const statistics = useQuery<StatisticsByDates | null>(
    useCallback(async () => {
      const result = await StatisticsService.ByDates.get({
        startDate: startDate.format(Constants.DATE),
        endDate: endDate.format(Constants.DATE),
      });

      if (result.status === 200) {
        return result.data;
      } else {
        throw new Error(result.data);
      }
    }, [endDate, startDate]),
    useMemo(() => ({ initialData: null }), []),
  );

  return { ...statistics };
};
