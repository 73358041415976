import React, { useContext, useMemo } from 'react';
import { Button, Descriptions, Space, Spin, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import { useCustomerDetails } from '../hooks/use-details';
import * as Icons from '@ant-design/icons';
import { NextTableProvider } from '../../../shared/modules/next-table/context/provider';
import { fetchCashRegisterChecksTableDataUseCase } from '../../cash-register/use-cases/fetch-checks-table-data';
import { CashRegisterChecksTableContext } from '../../cash-register/context';
import { CashRegisterChecksTable } from '../../cash-register/containers/checks-table';
import { fetchAppointmentsTableDataUseCase } from '../../appointments/use-cases/fetch-table-data';
import { AppointmentsTableContext } from '../../appointments/context';
import { AppointmentsTable } from '../../appointments/containers/table';
import { MeContext } from '../../me/context/context';

export const CustomerDetails = () => {
  const params = useParams<{ id: string }>();
  const { can } = useContext(MeContext);

  const { data, loading, edit, remove } = useCustomerDetails(params.id);

  const extra = useMemo(
    () => (
      <Space size={8}>
        {can(36) && (
          <Button size='small' type='link' onClick={edit} icon={<Icons.EditOutlined />}>
            Düzəliş et
          </Button>
        )}
        {can(37) && (
          <Button size='small' type='link' danger={true} onClick={remove} icon={<Icons.DeleteOutlined />}>
            Sil
          </Button>
        )}
      </Space>
    ),
    [can, edit, remove],
  );

  const defaultTableState = useMemo(() => ({ filters: [{ id: 'client_id', value: params.id }] }), [params.id]);

  return (
    <Spin spinning={loading}>
      <Tabs destroyInactiveTabPane={true} style={{ marginTop: -12 }}>
        <Tabs.TabPane key='details' tab='Ümumi'>
          <div>
            {data && (
              <Descriptions size='small' bordered={true} column={1} extra={extra}>
                <Descriptions.Item label='Kod'>{data.id}</Descriptions.Item>
                <Descriptions.Item label='Ad'>{data.firstname}</Descriptions.Item>
                <Descriptions.Item label='Soyad'>{data.lastname}</Descriptions.Item>
                <Descriptions.Item label='Ata adı'>{data.patronymic}</Descriptions.Item>
                <Descriptions.Item label='Email'>{data.email}</Descriptions.Item>
                <Descriptions.Item label='Cinsi'>{data.gender && (data.gender === 'male' ? 'Kişi' : 'Qadın')}</Descriptions.Item>
                <Descriptions.Item label='Sığorta nömrəsi'>{data.insuranceNumber}</Descriptions.Item>
                <Descriptions.Item label='Franşiza'>{data.franchise}</Descriptions.Item>
                <Descriptions.Item label='Milliyəti'>{data.nationality}</Descriptions.Item>
                <Descriptions.Item label='Telefon nömrəsi'>{data.phoneNumber}</Descriptions.Item>
                <Descriptions.Item label='FİN Kod'>{data.passport.secret}</Descriptions.Item>
                <Descriptions.Item label='Status'>{data.status.name}</Descriptions.Item>
                <Descriptions.Item label='Qeydiyyat tarixi'>{data.createdAt}</Descriptions.Item>
              </Descriptions>
            )}
          </div>
        </Tabs.TabPane>
        {can(64) && (
          <Tabs.TabPane key='appointments' tab='Ziyarətlər'>
            <NextTableProvider onFetch={fetchAppointmentsTableDataUseCase} context={AppointmentsTableContext} defaultState={defaultTableState}>
              <AppointmentsTable limited={true} />
            </NextTableProvider>
          </Tabs.TabPane>
        )}
        {can(94) && (
          <Tabs.TabPane key='checks' tab='Ödənilən çeklər'>
            <NextTableProvider
              onFetch={fetchCashRegisterChecksTableDataUseCase}
              context={CashRegisterChecksTableContext}
              defaultState={defaultTableState}
            >
              <CashRegisterChecksTable limited={true} />
            </NextTableProvider>
          </Tabs.TabPane>
        )}
      </Tabs>
    </Spin>
  );
};
