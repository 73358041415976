import React, { FC, memo } from 'react';
import { Form, Radio } from 'antd';
import { FormItemProps } from 'antd/es/form';
import { FieldConfig, FieldInputProps, FieldMetaProps, useField } from 'formik';
import { RadioGroupProps } from 'antd/es/radio';
import { twoLevelShallowEqualObject } from '../../../utils/two-level-shallow-equal-object';

export type RadioFieldProps = FieldConfig & {
  item?: FormItemProps;
  input?: RadioGroupProps;
};

type MemoizedRadioFieldProps = {
  item?: FormItemProps;
  input?: RadioGroupProps;
  field: FieldInputProps<any>;
  meta: FieldMetaProps<any>;
  children: any;
};

const MemoizedRadioField = memo<MemoizedRadioFieldProps>(({ item, input, field, meta, children }) => {
  return (
    <Form.Item {...item} validateStatus={meta.touched && !!meta.error ? 'error' : undefined} help={meta.error}>
      <Radio.Group {...field} {...input}>
        {children}
      </Radio.Group>
    </Form.Item>
  );
}, twoLevelShallowEqualObject);

export const RadioField: FC<RadioFieldProps> = ({ item, input, children, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <MemoizedRadioField field={field} meta={meta} input={input} item={item}>
      {children}
    </MemoizedRadioField>
  );
};
