import { ApiResponse } from '../../../shared/utils/api-response';
import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { NextTableMetaResponse } from '../../../shared/modules/next-table/types';
import { CashRegisterMapper } from '../mappers';
import { nextTableMetaMapper } from '../../../shared/modules/next-table/utils/meta-mapper';
import { XReport } from '../types/x-report';

export class CashRegisterXReportsService {
  public static async getAll(
    query?: Record<string, any>,
  ): Promise<ApiResponse<200, XReport[], NextTableMetaResponse> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/device/x_reports', query);

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data, ...meta } = await response.json();
        return new ApiResponse(200, CashRegisterMapper.XReports.manyFromApi(data), nextTableMetaMapper(meta));
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }

  public static async print(): Promise<ApiResponse<200> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/device/getXReport');

    try {
      const response = await caller(url, { method: 'POST' });

      if (response.ok) {
        return new ApiResponse(200, null, null);
      } else if (response.status === 422 || response.status === 400) {
        const { errors } = await response.json();
        const mappedErrors = Object.values<string[]>(errors).flat().join(', ');

        return new ApiResponse(400, mappedErrors, null);
      } else {
        return new ApiResponse(400, 'Əməliyyat aparıla bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }
}
