import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { StatisticsRoutes } from '../constants';

import { StatisticsPage } from '../pages';

export const StatisticsRouter = () => {
  return (
    <Switch>
      <Route path={StatisticsRoutes.Index} component={StatisticsPage} />
    </Switch>
  );
};
