import React from 'react';
import ReactDOM from 'react-dom';
import Handlebars from 'hbs';
import App from './App';

import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.min.css';

Handlebars.registerHelper('ifEquals', function (arg1, arg2, options) {
  // @ts-ignore
  return arg1 === arg2 ? options.fn(this) : options.inverse(this);
});

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
