import { formDataFlat } from '../../../shared/utils/form-data-flat';
import flatten from 'flat';
import {
  CashRegisterDelayedSaleFormData,
  CashRegisterDelayedSaleFormDataApi,
  CashRegisterDelayedSalesFormErrors,
  CashRegisterDelayedSalesFormErrorsApi,
} from '../types/delayed-sale';
import { Constants } from '../../../shared/constants';

export class CashRegisterDelayedSalesMapper {
  public static formDataToApi(formData: CashRegisterDelayedSaleFormData): CashRegisterDelayedSaleFormDataApi {
    const mappedData = {
      cashPayment: formData.payment.cash,
      cardPayment: formData.payment.card,
      bonusPayment: formData.payment.bonus,
      depositPayment: formData.payment.deposit,
      clientName: formData.clientName,
      cashierName: formData.cashierName,
      currency: formData.currency,
      firstOperationAtUtc: formData.startedAt?.format(Constants.DATE_TIME),
      lastOperationAtUtc: formData.endedAt?.format(Constants.DATE_TIME),
      items: formData.items.map(({ id, name, price, quantity, vatType }) => ({
        code: id,
        name,
        purchasePrice: price,
        quantity,
        vatType: (() => {
          switch (vatType) {
            case 'free':
              return '3';
            case 'tradeSupplement':
              return '2';
            default:
              return '1';
          }
        })(),
      })),
    };

    return formDataFlat(mappedData);
  }

  public static formErrorsFromApi(errors: CashRegisterDelayedSalesFormErrorsApi): CashRegisterDelayedSalesFormErrors {
    const unFlattenedErrors = flatten.unflatten(errors);

    return {
      clientName: unFlattenedErrors.clientName?.join(', '),
      cashierName: unFlattenedErrors.cashierName?.join(', '),
      currency: unFlattenedErrors.currency?.join(', '),
      startedAt: unFlattenedErrors.firstOperationAtUtc?.join(', '),
      endedAt: unFlattenedErrors.lastOperationAtUtc?.join(', '),
      payment: {
        cash: unFlattenedErrors.cash?.join(', '),
        card: unFlattenedErrors.card?.join(', '),
        bonus: unFlattenedErrors.bonus?.join(', '),
        deposit: unFlattenedErrors.deposit?.join(', '),
      },
      items: unFlattenedErrors.items?.map(({ code, name, purchasePrice, quantity, vatType }) => ({
        id: code?.join(', '),
        name: name?.join(', '),
        price: purchasePrice?.join(', '),
        quantity: quantity?.join(', '),
        vatType: vatType?.join(', '),
      })),
    };
  }
}
