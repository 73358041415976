export const TransferOutlined = () => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height='1em'
      width='1em'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='-40 0 480 480'
    >
      <path d='m296 336c-4.417969 0-8 3.582031-8 8v24h-248c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h248v56c0 13.253906-10.746094 24-24 24h-224c-13.253906 0-24-10.746094-24-24v-400c0-13.253906 10.746094-24 24-24h224c13.253906 0 24 10.746094 24 24v48c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-48c0-22.089844-17.910156-40-40-40h-224c-22.089844 0-40 17.910156-40 40v400c0 22.089844 17.910156 40 40 40h224c22.089844 0 40-17.910156 40-40v-96c0-4.417969-3.582031-8-8-8zm0 0' />
      <path d='m184 416c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-64c-8.835938 0-16 7.164062-16 16v16c0 8.835938 7.164062 16 16 16h64c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-64v-16zm0 0' />
      <path d='m296 256c-8.835938 0-16-7.164062-16-16 0-4.417969-3.582031-8-8-8s-8 3.582031-8 8c.046875 14.554688 9.910156 27.242188 24 30.878906v9.121094c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-9.121094c15.40625-4.183594 25.375-19.082031 23.359375-34.917968-2.015625-15.839844-15.394531-27.769532-31.359375-27.960938-8.835938 0-16-7.164062-16-16s7.164062-16 16-16 16 7.164062 16 16c0 4.417969 3.582031 8 8 8s8-3.582031 8-8c-.046875-14.554688-9.910156-27.242188-24-30.878906v-9.121094c0-4.417969-3.582031-8-8-8s-8 3.582031-8 8v9.121094c-15.40625 4.183594-25.375 19.082031-23.359375 34.917968 2.015625 15.839844 15.394531 27.769532 31.359375 27.960938 8.835938 0 16 7.164062 16 16s-7.164062 16-16 16zm0 0' />
      <path d='m296 112c-33.78125-.015625-65.464844 16.378906-84.964844 43.960938-19.503906 27.582031-24.390625 62.917968-13.113281 94.757812 1.476563 4.175781 6.0625 6.363281 10.238281 4.882812 4.175782-1.480468 6.359375-6.066406 4.878906-10.242187-3.351562-9.425781-5.058593-19.355469-5.039062-29.359375.015625-41.980469 29.679688-78.109375 70.859375-86.285156 41.175781-8.175782 82.398437 13.871094 98.453125 52.660156 16.054688 38.792969 2.472656 83.519531-32.441406 106.835938-34.914063 23.316406-81.433594 18.722656-111.109375-10.972657-3.175781-2.714843-7.90625-2.535156-10.863281.421875-2.953126 2.953125-3.136719 7.6875-.417969 10.859375 33.28125 33.300781 84.78125 40.085938 125.554687 16.550781 40.769532-23.539062 60.648438-71.53125 48.453125-117.003906-12.191406-45.472656-53.410156-77.082031-100.488281-77.066406zm0 0' />
      <path d='m112 152h56c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-48v-8c-.007812-3.078125-1.78125-5.875-4.558594-7.199219-2.753906-1.308593-6.011718-.933593-8.402344.960938l-40 32c-1.890624 1.515625-2.992187 3.8125-2.992187 6.238281s1.101563 4.722656 2.992187 6.238281l40 32c1.410157 1.132813 3.15625 1.75 4.960938 1.761719 1.195312.019531 2.375-.253906 3.441406-.800781 2.777344-1.324219 4.550782-4.121094 4.558594-7.199219v-8h48c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-56c-4.179688-.011719-7.664062 3.195312-8 7.359375l-19.199219-15.359375 19.199219-15.359375c.335938 4.164063 3.820312 7.371094 8 7.359375zm0 0' />
      <path d='m124.558594 295.199219c1.066406.546875 2.246094.820312 3.441406.800781 1.804688-.011719 3.550781-.628906 4.960938-1.761719l40-32c1.890624-1.515625 2.992187-3.8125 2.992187-6.238281s-1.101563-4.722656-2.992187-6.238281l-40-32c-2.390626-1.894531-5.648438-2.269531-8.402344-.960938-2.777344 1.324219-4.550782 4.121094-4.558594 7.199219v8h-48c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h56c4.179688.011719 7.664062-3.195312 8-7.359375l19.199219 15.359375-19.199219 15.359375c-.335938-4.164063-3.820312-7.371094-8-7.359375h-56c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h48v8c.007812 3.078125 1.78125 5.875 4.558594 7.199219zm0 0' />
      <path d='m64 48c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8 3.582031-8 8-8 8 3.582031 8 8zm0 0' />
      <path d='m96 48c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8 3.582031-8 8-8 8 3.582031 8 8zm0 0' />
      <path d='m224 104c0-4.417969-3.582031-8-8-8h-48c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h48c4.417969 0 8-3.582031 8-8zm0 0' />
      <path d='m144 336h56c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-56c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8zm0 0' />
    </svg>
  );
};
