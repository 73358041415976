import React from 'react';
import { Modal } from 'antd';
import * as Icons from '@ant-design/icons';

import { CustomerDetails } from '../containers/details';
import { useCloseModal } from '../../../shared/hooks/use-close-modal';

export const CustomerDetailsPage = () => {
  const { canGoBack, onBack, onClose } = useCloseModal();

  return (
    <Modal
      closeIcon={canGoBack && <Icons.ArrowLeftOutlined onClick={onBack}>Geri</Icons.ArrowLeftOutlined>}
      visible={true}
      width={992}
      footer={null}
      onCancel={() => onClose('/customers')}
    >
      <CustomerDetails />
    </Modal>
  );
};
