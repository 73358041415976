import { useCallback, useMemo } from 'react';
import { StatisticsService } from '../services';
import { useQuery } from '../../../shared/modules/use-query/hooks';
import { StatisticsAppointmentsByModels } from '../types/appointments';
import { Constants } from '../../../shared/constants';

export const useStatisticsAppointmentsByModels = ({ startDate, endDate }) => {
  return useQuery<StatisticsAppointmentsByModels>(
    useCallback(async () => {
      const result = await StatisticsService.Appointments.getByModels({
        startDate: startDate.format(Constants.DATE),
        endDate: endDate.format(Constants.DATE),
      });

      if (result.status === 200) {
        return result.data;
      } else {
        throw new Error(result.data);
      }
    }, [startDate, endDate]),
    useMemo(() => ({ initialData: { services: [], doctors: [] } }), []),
  );
};
