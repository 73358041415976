import { shallowEqualObjects } from 'shallow-equal';

export const twoLevelShallowEqualObject = (prevObject: object, currObject: object): boolean => {
  for (let key in prevObject) {
    if (prevObject.hasOwnProperty(key) && currObject.hasOwnProperty(key)) {
      const prevNestedObject = prevObject[key];
      const currNestedObject = currObject[key];

      if (!shallowEqualObjects(prevNestedObject, currNestedObject)) {
        return false;
      }
    } else {
      return false;
    }
  }

  return true;
};
