import { StatisticsByDates, StatisticsByDatesApi } from '../types/by-dates';

export class StatisticsByDatesMapper {
  public static fromApi(statistics: StatisticsByDatesApi): StatisticsByDates {
    return {
      appointments: {
        byDays: statistics.visits_by_day.map((item) => ({ date: item.date, total: item.count })),
        byMonths: statistics.visits_by_month.map((item) => ({ date: item.date, total: item.count })),
      },
      customers: {
        byDays: statistics.clients_by_day.map((item) => ({ date: item.date, total: item.count })),
        byMonths: statistics.clients_by_month.map((item) => ({ date: item.date, total: item.count })),
      },
    };
  }
}
