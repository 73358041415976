import { useQuery } from '../../../shared/modules/use-query/hooks';
import { Status } from '../types';
import { useCallback, useMemo } from 'react';
import { StatusService } from '../services';

export const useStatusList = ({ modelId }: { modelId?: number } = {}) => {
  return useQuery<Status[]>(
    useCallback(async () => {
      const result = await StatusService.getAll({ page: 1, per_page: 5000, model_id: modelId });
      if (result.status === 200) {
        return result.data;
      } else {
        throw new Error(result.data);
      }
    }, [modelId]),
    useMemo(() => ({ initialData: [] }), []),
  );
};
