import { XReport, XReportApi, XReportJSONApi } from '../types/x-report';

export class CashRegisterXReportsMapper {
  public static oneFromApi(xReport: XReportApi): XReport {
    const json: XReportJSONApi | null = JSON.parse(xReport.json);

    return {
      id: xReport.id,
      user: { id: 0, name: xReport.user_name },
      data: json
        ? {
            number: json.reportNumber,
            documents: {
              firstNumber: json.firstDocNumber,
              lastNumber: json.firstDocNumber,
              sentCount: json.docCountToSend,
            },
            amountsByCurrencies: json.currencies.map((currency) => ({
              name: currency.currency,
              sale: {
                count: currency.saleCount,
                summary: {
                  all: currency.saleSum,
                  cash: currency.saleCashSum,
                  cashless: currency.saleCashlessSum,
                  prepayment: currency.salePrepaymentSum,
                  credit: currency.saleCreditSum,
                  bonus: currency.saleBonusSum,
                },
                vats: currency.saleVatAmounts.map((vat) => ({
                  summary: vat.vatSum,
                  percent: vat.vatPercent,
                })),
              },
              refund: {
                count: currency.moneyBackCount,
                summary: {
                  all: currency.moneyBackSum,
                  cash: currency.moneyBackCashSum,
                  cashless: currency.moneyBackCashlessSum,
                  prepayment: currency.moneyBackPrepaymentSum,
                  credit: currency.moneyBackCreditSum,
                  bonus: currency.moneyBackBonusSum,
                },
                vats: currency.moneyBackVatAmounts.map((vat) => ({
                  summary: vat.vatSum,
                  percent: vat.vatPercent,
                })),
              },
              rollback: {
                count: currency.rollbackCount,
                summary: {
                  all: currency.rollbackSum,
                  cash: currency.rollbackCashSum,
                  cashless: currency.rollbackCashlessSum,
                  prepayment: currency.rollbackPrepaymentSum,
                  credit: currency.rollbackCreditSum,
                  bonus: currency.rollbackBonusSum,
                },
                vats: currency.rollbackVatAmounts.map((vat) => ({
                  summary: vat.vatSum,
                  percent: vat.vatPercent,
                })),
              },
              correction: {
                count: currency.correctionCount,
                summary: {
                  all: currency.correctionSum,
                  cash: currency.correctionCashSum,
                  cashless: currency.correctionCashlessSum,
                  prepayment: currency.correctionPrepaymentSum,
                  credit: currency.correctionCreditSum,
                  bonus: currency.correctionBonusSum,
                },
                vats: currency.correctionVatAmounts.map((vat) => ({
                  summary: vat.vatSum,
                  percent: vat.vatPercent,
                })),
              },
              withdraw: {
                count: currency.withdrawCount,
                summary: currency.withdrawSum,
              },
              deposit: {
                count: currency.depositCount,
                summary: currency.depositSum,
              },
            })),
            shiftOpenedAt: json.shiftOpenAtUtc,
            createdAt: json.createdAtUtc,
          }
        : null,
      createdAt: xReport.created_at,
    };
  }

  public static manyFromApi(xReports: XReportApi[]): XReport[] {
    return xReports.map((xReport) => this.oneFromApi(xReport));
  }
}
