import { Button, Layout } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';

export const StyledHeader = styled(Layout.Header)<{ $wide?: boolean }>`
  background-color: #fff;
  height: 46px !important;
  line-height: 46px !important;
  padding: 0;
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: ${({ $wide }) => (!$wide ? '224px' : '46px')};
  right: 0;
  z-index: 10;
  transition: left 0.2s;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
`;

const HeaderIconStyles = css`
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 18px;
`;

export const StyledMenuFoldIcon = styled(MenuFoldOutlined)`
  ${HeaderIconStyles}
`;

export const StyledMenuUnfoldIcon = styled(MenuUnfoldOutlined)`
  ${HeaderIconStyles}
`;

export const StyledHeaderButton = styled(Button)<{ $ghost?: boolean }>`
  border: none;
  box-shadow: none;
  height: 46px;
  padding: 0 12px;
  background-color: ${({ $ghost }) => ($ghost ? 'transparent' : '#fff')};
  border-radius: 0;

  &:hover {
    background-color: ${({ $ghost }) => ($ghost ? 'rgba(0,0,0,0.02)' : '#fafafa')};
  }

  &::after {
    content: none;
  }
`;

export const StyledPortalArea = styled.div.attrs({ id: 'app-header-portal-area' })`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0 8px;
  box-shadow: -18px 0 18px -30px rgba(0, 0, 0, 0.5) inset, 18px 0 18px -30px rgba(0, 0, 0, 0.5) inset;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    white-space: nowrap;
  }

  & > div {
    flex: 1;
  }
`;

export const StyledHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledHeaderRight = styled.div`
  display: flex;
  align-items: center;
  padding-left: 12px;
`;
