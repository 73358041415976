import Cookies from 'js-cookie';

import { LoginFormData } from '../types';
import { MeMappers } from '../mappers';

import { ApiResponse } from '../../../shared/utils/api-response';
import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';

export class MeService {
  public static async getMe() {
    const url = urlMaker('/api/users/data');

    try {
      const response = await caller(url);

      if (response.ok) {
        return new ApiResponse(200, MeMappers.meFromApi(await response.json()), null);
      }

      return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi.', null);
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async logout() {
    const url = urlMaker('/api/logout');

    try {
      Cookies.remove('refreshToken');
      Cookies.remove('accessToken');
      Cookies.remove('tokenType');
      const response = await caller(url, { method: 'POST' });

      if (response.ok) {
        return new ApiResponse(200, null, null);
      }

      return new ApiResponse(400, 'Əməliyyat aparıla bilmədi.', null);
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async login(formData: LoginFormData) {
    const url = urlMaker('/api/login');
    const body = new FormData();
    const mappedFormData = MeMappers.loginFormToApi(formData);

    Object.entries(mappedFormData).forEach(([key, value]) => body.append(key, value));

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResponse(200, MeMappers.loginFormSuccessFromApi(await response.json()), null);
      } else if (response.status === 400) {
        const { error, errors } = await response.json();

        if (error === 'invalid_grant') {
          return new ApiResponse(401, 'İstifadəçi məlumatları yalnış daxil edilib.', null);
        } else if (errors) {
          return new ApiResponse(400, Object.values(errors).flat().join('. '), null);
        }
      } else if (response.status === 422) {
        const { errors } = await response.json();

        return new ApiResponse(422, MeMappers.loginFormErrorsFromApi(errors), null);
      } else if (response.status === 401) {
        const { message } = await response.json();

        if (message === 'Blocked') {
          return new ApiResponse(403, 'Sistemə girişinizə müvəqqəti olaraq məhdudiyyət qoyulmuşdur.', null);
        }
      }

      return new ApiResponse(400, 'Əməliyyat aparıla bilmədi.', null);
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }
}
