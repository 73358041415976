import { createGlobalStyle } from 'styled-components';

export const StyledAntdGlobal = createGlobalStyle`
  .ant-form-item {
    margin-bottom: 1rem;
  }
  
  .ant-transfer-list {
    width: unset;
    flex: 1;
    height: 340px;
  }
  
  .ant-btn-group .ant-btn.ant-btn-icon-only > .anticon {
    font-size: 14px;
  }
  
  .ant-btn-group > a {
    margin-left: -1px;
  }
`;
