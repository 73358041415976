import { useCallback, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import { useQuery } from '../../../../shared/modules/use-query/hooks';
import { UseQueryOptions } from '../../../../shared/modules/use-query/types';
import { FormOptions } from '../../../../shared/modules/form/types';

import { CashboxesService } from '../services';
import { CashboxMappers } from '../mappers';
import { Cashbox, CashboxFormData } from '../types';

export const useCashboxForm = ({ type = 'create', redirectUrl }: FormOptions) => {
  const params = useParams<{ id?: string }>();
  const history = useHistory();
  const baseInitialValues = useMemo<CashboxFormData>(() => ({ amount: '', cashboxName: '', description: '' }), []);

  const initialValues = useQuery(
    useCallback(async () => {
      const result = await CashboxesService.getOneById(params.id as string);

      if (result.status === 200) {
        return CashboxMappers.formInitializer(result.data as Cashbox) as CashboxFormData;
      } else {
        return baseInitialValues;
      }
    }, [baseInitialValues, params.id]),
    useMemo<UseQueryOptions>(
      () => ({
        initialData: baseInitialValues,
        enabled: !!params.id,
      }),
      [baseInitialValues, params.id],
    ),
  );

  const onSubmit = useCallback(
    async (values: CashboxFormData, helpers: FormikHelpers<CashboxFormData>) => {
      helpers.setStatus(null);
      const result = await CashboxesService.createOrUpdate(values, type);

      if (result.status === 200) {
        if (redirectUrl) {
          history.push(redirectUrl);
        } else {
          helpers.resetForm();
        }
        message.success('Məlumatlar müvəffəqiyyətlə yeniləndi.');
      } else if (result.status === 422) {
        helpers.setErrors(result.data);
      } else {
        helpers.setStatus({ type: 'error', message: result.data });
      }
    },
    [history, redirectUrl, type],
  );

  return { initialValues, onSubmit };
};
