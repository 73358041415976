import React from 'react';
import { CashboxesRouter } from './router';
import { NextTableProvider } from '../../../shared/modules/next-table/context/provider';
import { fetchCashboxesChecksTableDataUseCase } from './use-cases/fetch-checks-table-data';
import { CashboxesTableContext } from './context';

const CashboxesModule = () => {
  return (
    <NextTableProvider onFetch={fetchCashboxesChecksTableDataUseCase} context={CashboxesTableContext}>
      <CashboxesRouter />
    </NextTableProvider>
  );
};

export default CashboxesModule;
