import { useQuery } from '../../../shared/modules/use-query/hooks';
import { Customer } from '../types';
import { useCallback, useMemo } from 'react';
import { CustomersService } from '../services';
import { UseQueryOptions } from '../../../shared/modules/use-query/types';
import { useHistory, useLocation } from 'react-router-dom';
import { localURLMaker } from '../../../shared/utils/url-maker';
import { message, Modal } from 'antd';

export const useCustomerDetails = (id?: number | string) => {
  const location = useLocation();
  const history = useHistory();

  const { reFetch, data, loading } = useQuery<Customer | null>(
    useCallback(async () => {
      const result = await CustomersService.getOneById(id || 0);

      if (result.status === 200) {
        return result.data;
      } else {
        throw new Error(result.data);
      }
    }, [id]),
    useMemo<UseQueryOptions>(
      () => ({
        initialData: null,
        enabled: !!id,
      }),
      [id],
    ),
  );

  const edit = useCallback(() => {
    history.push(localURLMaker('/customers/:id/update', { id: id || 0 }), { background: location });
  }, [history, id, location]);

  const remove = useCallback(() => {
    const handler = async () => {
      message.loading({ key: 'message', content: 'Əməliyyat aparılır.' });
      const result = await CustomersService.actionByIds(id ? [id] : [], 'remove');

      if (result.status === 200) {
        message.success({ key: 'message', content: 'Silmə əməliyyatı müvəffəqiyyətlə başa çatdı' });
        await reFetch();
      } else {
        message.error({ key: 'message', content: result.data });
      }
    };

    Modal.confirm({
      title: 'Silmə əməliyyatını etməyinizə əminsinizmi?',
      content: 'Silmə əməliyyatını etdikdən sonra məlumatların geri qaytarılması mümkün olmayacaq.',
      onOk: handler,
    });
  }, [id, reFetch]);

  return { data, loading, edit, remove };
};
