import React, { Fragment } from 'react';
import { Descriptions, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { StyledPageHeader } from '../../../../shared/modules/antd/styled/page-header';
import { useCashboxDetails } from '../hooks/use-details';

export const CashboxDetails = () => {
  const params = useParams<{ id: string }>();
  const { data, loading } = useCashboxDetails(params.id);

  return (
    <Spin spinning={loading}>
      <div>
        {data && (
          <Fragment>
            <StyledPageHeader $size='small' title={`${data.cashboxName} kassa`} />
            <Descriptions size='small' bordered={true} column={1}>
              <Descriptions.Item label='Kassa adı'>{data.cashboxName}</Descriptions.Item>
              <Descriptions.Item label='Kod'>{data.id}</Descriptions.Item>
              <Descriptions.Item label='İlkin məbləğ'>{data.initialAmount}</Descriptions.Item>
              <Descriptions.Item label='Şərh'>{data.description}</Descriptions.Item>
              <Descriptions.Item label='Yaradılma tarixi'>{data.createdAt}</Descriptions.Item>
            </Descriptions>
          </Fragment>
        )}
      </div>
    </Spin>
  );
};
