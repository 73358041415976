import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Column } from 'react-table';
import { Button, Dropdown, Menu, message, Modal } from 'antd';
import * as Icons from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import { NextTableContext } from '../../../../shared/modules/next-table/types';
import { nextTableColumns } from '../../../../shared/modules/next-table/helpers/next-table-columns';
import { useSearchParams } from '../../../../shared/hooks/use-search-params';

import { CashboxesTableContext } from '../context';
import { Cashbox } from '../types';
import { localURLMaker } from '../../../../shared/utils/url-maker';
import { StopPropagation } from '../../../../shared/components/stop-propagation';
import { CashboxesService } from '../services';
import { MeContext } from '../../../me/context/context';

export const useCashboxesTable = () => {
  const { handleFetch } = useContext<NextTableContext>(CashboxesTableContext);
  const history = useHistory();
  const { can } = useContext(MeContext);

  const getRowProps = useCallback(
    (id) => ({
      onDoubleClick: () => history.push(localURLMaker('cash-flow/cashboxes/:id/details', { id })),
    }),
    [history],
  );

  const columns = useMemo<Column<Cashbox>[]>(
    () => [
      {
        ...nextTableColumns.actions,
        Cell: ({ row: { original } }) => {
          const goTo = useCallback(
            (type: string) => {
              history.push(localURLMaker('/cash-flow/cashboxes/:id/:type', { id: original.id, type }));
            },
            [original.id],
          );

          const handleAction = useCallback(
            async (actionType) => {
              const handler = async () => {
                message.loading({ key: 'message', content: 'Əməliyyat aparılır.' });
                const result = await CashboxesService.actionByIds([original.id], actionType);

                if (result.status === 200) {
                  message.success({ key: 'message', content: 'Əməliyyat müvəffəqiyyətlə başa çatdı' });
                  history.push(localURLMaker('/cash-flow/cashboxes', {}, { reFetchCashboxesTable: true }));
                } else {
                  message.error({ key: 'message', content: result.data });
                }
              };

              Modal.confirm({
                title: 'Əməliyyatı etməyinizə əminsinizmi?',
                content: 'Əməliyyatı etdikdən sonra məlumatların geri qaytarılması mümkün olmayacaq.',
                onOk: handler,
              });
            },
            [original.id],
          );

          const overlay = (
            <Menu>
              {can(111) && (
                <Menu.Item key='update' onClick={() => goTo('update')} icon={<Icons.EditOutlined />}>
                  Dəyişdir
                </Menu.Item>
              )}
              {can(109) && (
                <Menu.Item key='details' onClick={() => goTo('details')} icon={<Icons.FileTextOutlined />}>
                  Ətraflı
                </Menu.Item>
              )}
              <Menu.Divider />
              {can(112) && (
                <Menu.Item key='remove' onClick={() => handleAction('remove')} icon={<Icons.DeleteOutlined />}>
                  Sil
                </Menu.Item>
              )}
            </Menu>
          );

          return (
            <StopPropagation>
              <Dropdown overlay={overlay}>
                <Button size='small' icon={<Icons.MoreOutlined />} />
              </Dropdown>
            </StopPropagation>
          );
        },
      },
      {
        ...nextTableColumns.smaller,
        accessor: (row) => row.id,
        id: 'id',
        Header: 'Kod',
      },
      {
        accessor: (row) => row.cashboxName,
        sortable: false,
        id: 'client_name',
        Header: 'Kassa adı',
      },
      {
        ...nextTableColumns.smaller,
        accessor: (row) => row.amount,
        id: 'amount',
        Header: 'Cari balans',
      },
      { ...nextTableColumns.date, accessor: (row) => row.createdAt, id: 'created_at', Header: 'Yaradılma tarixi' },
    ],
    [history, can],
  );

  const { searchParams, remove } = useSearchParams<{ reFetchCashboxesTable?: string }>();

  useEffect(() => {
    (async () => {
      if (searchParams.reFetchCashboxesTable) {
        remove.current('reFetchCashboxesTable');
        await handleFetch();
      }
    })();
  }, [handleFetch, remove, searchParams.reFetchCashboxesTable]);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  return { columns, getRowProps };
};
