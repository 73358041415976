import { Cashbox, CashboxApi, CashboxFormData, CashboxFormDataApi, CashboxFormErrorsApi, CashboxFormErrors } from '../types';

export class CashboxMappers {
  public static fromApi(cashboxApi: CashboxApi): Cashbox {
    return {
      id: cashboxApi.id,
      cashboxName: cashboxApi.cashbox_name,
      initialAmount: cashboxApi.initial_amount,
      amount: cashboxApi.amount,
      description: cashboxApi.descr,
      createdAt: cashboxApi.created_at,
    };
  }
  public static balanceToDomain(cashboxApi: any): any {
    return {
      name: cashboxApi.cashbox_name,
      amount: cashboxApi.amount,
    };
  }
  public static manyFromApi(appointmentsApi: CashboxApi[]): Cashbox[] {
    return appointmentsApi.map((item) => this.fromApi(item));
  }
  public static formDataToApi(formData: CashboxFormData): CashboxFormDataApi {
    return {
      cashbox_id: formData.id,
      cashbox_name: formData.cashboxName,
      descr: formData.description,
      amount: formData.amount,
    };
  }

  public static formErrorsFromApi(errors: CashboxFormErrorsApi): CashboxFormErrors {
    return {
      id: errors.cashbox_id,
      cashboxName: errors.cashbox_name,
      amount: errors.amount,
      description: errors.descr,
      _custom: errors._custom,
    };
  }

  public static formInitializer(cashbox: Cashbox): CashboxFormData {
    return {
      id: cashbox.id.toString(),
      cashboxName: cashbox.cashboxName,
      description: cashbox.description || '',
    };
  }
}
