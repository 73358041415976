import { CashRegister, CashRegisterApi, CashRegisterSlim, CashRegisterSlimApi, CashRegisterStatus, CashRegisterStatusApi } from '../types';
import { CashRegisterShiftsMapper } from './shifts';
import { CashRegisterChecksMapper } from './checks';
import { CashRegisterWithdrawsMapper } from './withdraws';
import { CashRegisterDepositsMapper } from './deposits';
import { CashRegisterSalesMapper } from './sales';
import { CashRegisterDelayedSalesMapper } from './delayed-sales';
import { CashRegisterXReportsMapper } from './x-reports';

export class CashRegisterMapper {
  public static Shifts = CashRegisterShiftsMapper;
  public static Checks = CashRegisterChecksMapper;
  public static Withdraws = CashRegisterWithdrawsMapper;
  public static Deposits = CashRegisterDepositsMapper;
  public static Sales = CashRegisterSalesMapper;
  public static DelayedSales = CashRegisterDelayedSalesMapper;
  public static XReports = CashRegisterXReportsMapper;

  public static deviceFromApi(cashRegister: CashRegisterSlimApi): CashRegisterSlim {
    return {
      id: cashRegister.id,
      name: cashRegister.device_name,
      connected: cashRegister.isConnectedPrinter,
      createdAt: cashRegister.created_at,
    };
  }

  public static manyDevicesFromApi(cashRegisters: CashRegisterSlimApi[]): CashRegisterSlim[] {
    return cashRegisters.map((item) => this.deviceFromApi(item));
  }

  public static detailsFromApi(cashRegister: CashRegisterApi): CashRegister {
    return {
      model: cashRegister.cashregister_model,
      taxNumber: cashRegister.cashbox_tax_number,
      factoryNumber: cashRegister.cashbox_factory_number,
      company: {
        name: cashRegister.company_name,
        taxNumber: cashRegister.company_tax_number,
      },
      object: {
        name: cashRegister.object_name,
        address: cashRegister.object_address,
        taxNumber: cashRegister.object_tax_number,
      },
      qrCode: {
        url: cashRegister.qr_code_url,
      },
      status: cashRegister.state,
    };
  }

  public static statusFromApi(status: CashRegisterStatusApi): CashRegisterStatus {
    return {
      id: status.params.device_id,
      name: status.params.device_name,
      connected: !status.data,
    };
  }
}
