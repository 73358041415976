import React, { useCallback, useContext, useMemo } from 'react';
import { Avatar, Dropdown, Menu, message } from 'antd';
import * as Icons from '@ant-design/icons';
import { useQuery } from 'react-query';
import { blue } from '@ant-design/colors';

import { StyledHeader, StyledMenuFoldIcon, StyledMenuUnfoldIcon } from '../styled';
import { useHeader } from '../hooks';
import { StyledHeaderButton, StyledHeaderLeft, StyledHeaderRight, StyledPortalArea } from '../styled/header';
import { CurrentCashRegister } from '../../cash-register/containers/current-device';
import { MeContext } from '../../me/context/context';
import { CashboxesService } from '../../cash-flow/cashboxes/services';
import { updateEmergencyService } from '../../me/services/update-emergency.service';

export const AppHeader = () => {
  const { can, state } = useContext(MeContext);
  const { toggleSidebar, sidebarIsOpen, avatarText, logout } = useHeader();
  const cashboxBalance = useQuery('cashbox-balance', async () => {
    const result = await CashboxesService.getCurrentBalance();

    if (result.status === 200) {
      return result.data;
    } else {
      throw new Error(result.data);
    }
  });

  const toggleEmergencyMode = useCallback(async () => {
    if (can(120)) {
      message.loading({ content: 'Yüklənir...', key: 'emergency-loading' });
      const result = await updateEmergencyService(!state.user.data?.emergencyModeIsEnabled);

      if (result.status === 200) {
        window.location.reload();
      } else {
        message.error({ key: 'emergency-loading', content: result.data });
      }
    }
  }, [can, state.user.data?.emergencyModeIsEnabled]);

  const avatarStyles = useMemo(() => {
    if (!!state.user.data?.emergencyModeIsEnabled) {
      return {
        backgroundColor: blue.primary,
        color: 'white',
      };
    }
  }, [state.user.data?.emergencyModeIsEnabled]);

  return (
    <StyledHeader $wide={!sidebarIsOpen}>
      <StyledHeaderLeft>
        {sidebarIsOpen ? <StyledMenuFoldIcon onClick={toggleSidebar} /> : <StyledMenuUnfoldIcon onClick={toggleSidebar} />}
      </StyledHeaderLeft>
      <StyledPortalArea />
      <StyledHeaderRight>
        {can(97) && can(98) && <CurrentCashRegister />}
        <Dropdown
          overlay={
            <Menu>
              {cashboxBalance.data && can(109) && (
                <Menu.Item disabled icon={<Icons.MoneyCollectOutlined />} style={{ color: 'black' }}>
                  {cashboxBalance.data.name}: {cashboxBalance.data.amount} AZN
                </Menu.Item>
              )}
              {!!state.user.data?.departments && (
                <Menu.Item disabled icon={<Icons.BuildOutlined />} style={{ color: 'black' }}>
                  {state.user.data?.departments.map((item) => item.name).join(', ')}
                </Menu.Item>
              )}
              <Menu.Item onClick={logout} icon={<Icons.LogoutOutlined />}>
                Çıxış
              </Menu.Item>
            </Menu>
          }
        >
          <StyledHeaderButton onDoubleClick={toggleEmergencyMode}>
            <Avatar style={avatarStyles}>{avatarText}</Avatar>
          </StyledHeaderButton>
        </Dropdown>
      </StyledHeaderRight>
    </StyledHeader>
  );
};
