import { useCallback, useMemo } from 'react';
import { StatisticsCounts } from '../types/counts';
import { useQuery } from '../../../shared/modules/use-query/hooks';
import { StatisticsService } from '../services';

export const useStatisticsCounts = () => {
  return useQuery<StatisticsCounts>(
    useCallback(async () => {
      const result = await StatisticsService.Counts.get();

      if (result.status === 200) {
        return result.data;
      } else {
        throw new Error(result.data);
      }
    }, []),
    useMemo(() => ({ initialData: { users: 0, customers: 0, doctors: 0, appointments: { all: 0, approved: 0 } } }), []),
  );
};
