import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Column } from 'react-table';
import { Button, Dropdown, Menu, message, Modal, Select } from 'antd';
import * as Icons from '@ant-design/icons';

import { nextTableColumns } from '../../../shared/modules/next-table/helpers/next-table-columns';
import { StopPropagation } from '../../../shared/components/stop-propagation';
import { useSearchParams } from '../../../shared/hooks/use-search-params';

import { CashRegisterChecksTableContext } from '../context';
import { CashRegisterService } from '../services';
import { NextTableCheckboxFilter } from '../../../shared/modules/next-table/components/filters/checkbox';
import { NextTableCheckCell } from '../../../shared/modules/next-table/components/cells/check';
import { Check } from '../types/check';
import { useHistory } from 'react-router-dom';
import { localURLMaker } from '../../../shared/utils/url-maker';
import { MeContext } from '../../me/context/context';
import { CashRegisterStatusContext } from '../context/status';
import { useQuery } from '../../../shared/modules/use-query/hooks';
import { CashRegisterSlim } from '../types';

export const useCashRegisterChecksTable = ({ limited = false }) => {
  const history = useHistory();
  const searchParams = useSearchParams<{ reFetchCashRegisterChecksTable?: string }>();

  const { handleFetch } = useContext(CashRegisterChecksTableContext);
  const { can } = useContext(MeContext);
  const cashRegisterStatus = useContext(CashRegisterStatusContext);

  const devices = useQuery<CashRegisterSlim[]>(
    useCallback(async () => {
      const result = await CashRegisterService.getAllDevices();

      if (result.status === 200) {
        return result.data;
      } else {
        throw new Error(result.data);
      }
    }, []),
    useMemo(() => ({ initialData: [] }), []),
  );

  const columns = useMemo<Column<Check>[]>(
    () => [
      {
        ...nextTableColumns.actions,
        Cell: ({ row: { original } }) => {
          const refund = useCallback(() => {
            if (!cashRegisterStatus.data?.connected) {
              message.error('Kassa əməliyyatları üçün ekranın yuxarı hissəsində olan "Obyekt seç" düyməsindən istifadə etməklə obyektinizi seçin');
              return false;
            }

            const action = async () => {
              const result = await CashRegisterService.Checks.refund(original.id);

              if (result.status === 200) {
                message.success('Çek geri qaytarıldı');
                await handleFetch();
              } else {
                message.error(result.data);
              }
            };

            Modal.confirm({
              title: 'Təsdiqləyin',
              content: 'Çeki geri qaytarmaq istəyinizdən əminsinizmi?',
              onOk: action,
            });
          }, [original.id]);

          const preview = useCallback(() => {
            history.push(localURLMaker('/cash_register/checks/:refId/preview', { refId: original.shortDocument.id }));
          }, [original.shortDocument.id]);

          const rePrint = useCallback(async () => {
            if (!cashRegisterStatus.data?.connected) {
              message.error('Kassa əməliyyatları üçün ekranın yuxarı hissəsində olan "Obyekt seç" düyməsindən istifadə etməklə obyektinizi seçin');
              return false;
            }

            const action = async () => {
              const result = await CashRegisterService.Checks.print(original.id);

              if (result.status === 200) {
                message.success('Çek çap edildi');
                await handleFetch();
              } else {
                message.error(result.data);
              }
            };

            Modal.confirm({
              title: 'Təsdiqləyin',
              content: 'Çek yenidən çap edilməsindən əminsinizmi?',
              onOk: action,
            });
          }, [original.id]);

          const remove = useCallback(() => {
            const action = async () => {
              const result = await CashRegisterService.Checks.remove([original.id]);

              if (result.status === 200) {
                message.success('Çek silindi');
                await handleFetch();
              } else {
                message.error(result.data);
              }
            };

            Modal.confirm({
              title: 'Təsdiqləyin',
              content: 'Çek silinməsindən əminsinizmi?',
              onOk: action,
            });
          }, [original.id]);

          const overlay = (
            <Menu>
              {can(91) && (
                <Menu.Item onClick={rePrint} icon={<Icons.PrinterOutlined />}>
                  Yenidən çap et
                </Menu.Item>
              )}
              {can(92) && (
                <Menu.Item onClick={refund} icon={<Icons.RollbackOutlined />}>
                  Geri qaytar
                </Menu.Item>
              )}
              <Menu.Item disabled={!original.shortDocument.id} onClick={preview} icon={<Icons.FileTextOutlined />}>
                E-Kassa çeki
              </Menu.Item>
              {can(93) && (
                <Menu.Item onClick={remove} icon={<Icons.DeleteOutlined />}>
                  Sil
                </Menu.Item>
              )}
            </Menu>
          );

          return (
            <StopPropagation>
              <Dropdown overlay={overlay}>
                <Button size='small' icon={<Icons.MoreOutlined />} />
              </Dropdown>
            </StopPropagation>
          );
        },
      },
      {
        ...nextTableColumns.smaller,
        accessor: (row) => row.id,
        id: 'id',
        Header: 'Kod',
      },
      {
        accessor: (row) => row.customer.name,
        id: 'client_name',
        filterable: !limited,
        sortable: !limited,
        Header: 'Müştəri',
      },
      {
        accessor: (row) => row.cashRegister.id,
        id: 'device_id',
        filterable: !limited,
        sortable: !limited,
        Header: 'Terminal',
        Cell: ({ row: { original } }) => original.cashRegister.name,
        Filter: ({ column: { filterValue, setFilter } }) => (
          <Select style={{ width: '100%' }} value={filterValue} onChange={setFilter} allowClear={true}>
            {devices.data.map((device) => (
              <Select key={device.id} value={device.id.toString()}>
                {device.name}
              </Select>
            ))}
          </Select>
        ),
      },
      {
        ...nextTableColumns.small,
        accessor: (row) => row.payment.cash,
        id: 'cashPayment',
        Header: 'Nağd',
      },
      {
        ...nextTableColumns.small,
        accessor: (row) => row.payment.card,
        id: 'cardPayment',
        Header: 'Bank',
      },
      {
        ...nextTableColumns.small,
        accessor: (row) => row.payment.bonus,
        id: 'bonusPayment',
        Header: 'Bonus',
      },
      {
        ...nextTableColumns.small,
        accessor: (row) => row.payment.deposit,
        id: 'depositPayment',
        Header: 'Deposit',
      },
      {
        ...nextTableColumns.normal,
        accessor: (row) => row.refunded,
        id: 'moneyback',
        Header: 'Qaytarılıb',
        Filter: NextTableCheckboxFilter,
        Cell: NextTableCheckCell,
      },
      {
        ...nextTableColumns.normal,
        accessor: (row) => row.changed,
        id: 'changed',
        Header: 'Dəyişdirilib',
        Filter: NextTableCheckboxFilter,
        Cell: NextTableCheckCell,
      },
      {
        ...nextTableColumns.normal,
        accessor: (row) => row.status.name,
        id: 'state_name',
        Header: 'Status',
      },
      {
        ...nextTableColumns.small,
        accessor: (row) => row.shift.id,
        id: 'shift_id',
        Header: 'Növbə',
      },
      {
        accessor: (row) => row.cashier.name,
        id: 'cashierName',
        Header: 'Kassir',
      },
      {
        ...nextTableColumns.date,
        accessor: (row) => row.createdAt,
        id: 'created_at',
        Header: 'Yaradılıb',
      },
    ],
    [can, cashRegisterStatus.data?.connected, devices.data, handleFetch, history, limited],
  );

  useEffect(() => {
    (async () => {
      if (searchParams.searchParams.reFetchCashRegisterChecksTable) {
        searchParams.remove.current('reFetchCashRegisterChecksTable');
        await handleFetch();
      }
    })();
  }, [searchParams.searchParams.reFetchCashRegisterChecksTable, searchParams.remove, handleFetch]);

  return { columns };
};
