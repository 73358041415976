import { useQuery } from '../../../shared/modules/use-query/hooks';
import { useCallback, useContext, useMemo, useState } from 'react';
import { CashRegisterService } from '../services';
import { CashRegisterSlim } from '../types';
import { message } from 'antd';
import { CashRegisterStatusContext } from '../context/status';

export const useCurrentCashRegisterForm = ({ onSubmitSucceed }) => {
  const { reFetch: reFetchStatus } = useContext(CashRegisterStatusContext);
  const [currentDeviceId, setCurrentDeviceId] = useState<string | undefined>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleDeviceChange = useCallback((value: string | undefined) => {
    setCurrentDeviceId(value);
  }, []);

  const devices = useQuery<CashRegisterSlim[]>(
    useCallback(async () => {
      const result = await CashRegisterService.getAllDevices();

      if (result.status === 200) {
        return result.data;
      } else {
        throw new Error(result.data);
      }
    }, []),
    useMemo(() => ({ initialData: [] }), []),
  );

  const handleSubmit = useCallback(async () => {
    if (currentDeviceId) {
      setSubmitting(true);
      const result = await CashRegisterService.updateCurrentDevice(currentDeviceId);

      if (result.status === 200) {
        reFetchStatus();
        message.success('Əməliyyat uğurla başa çatdı...');
        onSubmitSucceed?.();
      } else {
        message.error(result.data);
      }
      setSubmitting(false);
    } else {
      message.warning('Cihaz seçin...');
    }
  }, [currentDeviceId, onSubmitSucceed, reFetchStatus]);

  return { devices, currentDeviceId, handleDeviceChange, handleSubmit, submitting };
};
