import { StatisticsCounts, StatisticsCountsApi } from '../types/counts';

export class StatisticsCountsMapper {
  public static fromApi(counts: StatisticsCountsApi): StatisticsCounts {
    return {
      customers: counts.clients,
      doctors: counts.doctors,
      users: counts.users,
      appointments: {
        all: counts.visits.all,
        approved: counts.visits.approved,
      },
    };
  }
}
