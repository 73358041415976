import React from 'react';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';

import { localURLMaker } from '../../../../shared/utils/url-maker';

import { CashboxForm } from '../containers/form';

export const CreateCashboxPage = () => {
  const history = useHistory();

  return (
    <Modal visible={true} width={576} footer={null} title='Yeni Kassa' onCancel={() => history.push('/cash-flow/cashboxes')}>
      <CashboxForm type='create' redirectUrl={localURLMaker('/cash-flow/cashboxes', {}, { reFetchCashboxesTable: true })} />
    </Modal>
  );
};
