import { useQuery } from '../../../../shared/modules/use-query/hooks';
import { Cashbox } from '../types';
import { useCallback, useMemo } from 'react';
import { CashboxesService } from '../services';
import { UseQueryOptions } from '../../../../shared/modules/use-query/types';

export const useCashboxDetails = (id?: number | string) => {
  const { data, loading } = useQuery<Cashbox | null>(
    useCallback(async () => {
      const result = await CashboxesService.getOneById(id || 0);

      if (result.status === 200) {
        return result.data;
      } else {
        throw new Error(result.data);
      }
    }, [id]),
    useMemo<UseQueryOptions>(
      () => ({
        initialData: null,
        enabled: !!id,
      }),
      [id],
    ),
  );

  return { data, loading };
};
