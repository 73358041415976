import React, { FC } from 'react';
import { NextTable } from '../../../shared/modules/next-table/containers';
import { CustomersTableContext } from '../context';
import { useCustomersTable } from '../hooks/use-table';

export const CustomersTable: FC = () => {
  const { columns, getRowProps } = useCustomersTable();

  return <NextTable context={CustomersTableContext} columns={columns} getRowProps={getRowProps} />;
};
