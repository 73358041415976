import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { ApiResponse } from '../../../shared/utils/api-response';

import { StatisticsMapper } from '../mappers';
import { StatisticsAppointmentsByModels } from '../types/appointments';

export class StatisticsAppointmentsService {
  public static async getByModels({
    startDate,
    endDate,
  }): Promise<ApiResponse<200, StatisticsAppointmentsByModels> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/dashboard/pie_chart', { start_date: startDate, end_date: endDate });

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();

        return new ApiResponse(200, StatisticsMapper.Appointments.byModelsFromApi(data), null);
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }
}
