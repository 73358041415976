import React, { useMemo } from 'react';
import { Card, Col, Row } from 'antd';
import { useStatisticsAppointmentsByModels } from '../hooks/use-appointments-by-models';
import { Pie } from 'react-chartjs-2';

export const StatisticsAppointmentsByModels = ({ startDate, endDate }) => {
  const { data, loading } = useStatisticsAppointmentsByModels({ startDate, endDate });

  const hideLabel = useMemo(() => ({ legend: { display: false } }), []);

  return (
    <Row gutter={[12, 12]}>
      <Col span={24} lg={12} xxl={8}>
        <Card loading={loading} size='small' title='Satışlar (xidmətlər üzrə)'>
          <Pie
            options={hideLabel}
            data={{
              labels: data.services.map(({ name }) => name),
              datasets: [
                {
                  label: 'Ümumi say',
                  fill: false,
                  lineTension: 0.1,
                  backgroundColor: data.services.map(({ color }) => color),
                  borderColor: data.services.map(({ color }) => color),
                  borderCapStyle: 'round',
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: 'miter',
                  pointBorderColor: data.services.map(({ color }) => color),
                  pointBackgroundColor: '#ffffff',
                  pointBorderWidth: 1,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: data.services.map(({ color }) => color),
                  pointHoverBorderColor: data.services.map(({ color }) => color),
                  pointHoverBorderWidth: 2,
                  pointRadius: 1,
                  pointHitRadius: 10,
                  data: data.services.map(({ total }) => total),
                },
              ],
            }}
          />
        </Card>
      </Col>
      <Col span={24} lg={12} xxl={8}>
        <Card size='small' loading={loading} title='Satışlar (həkimlər üzrə)'>
          <Pie
            options={hideLabel}
            data={{
              labels: data.doctors.map(({ name }) => name),
              datasets: [
                {
                  label: 'Ümumi say',
                  fill: false,
                  lineTension: 0.1,
                  backgroundColor: data.doctors.map(({ color }) => color),
                  borderColor: data.doctors.map(({ color }) => color),
                  borderCapStyle: 'round',
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: 'miter',
                  pointBorderColor: data.doctors.map(({ color }) => color),
                  pointBackgroundColor: '#ffffff',
                  pointBorderWidth: 1,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: data.doctors.map(({ color }) => color),
                  pointHoverBorderColor: data.doctors.map(({ color }) => color),
                  pointHoverBorderWidth: 2,
                  pointRadius: 1,
                  pointHitRadius: 10,
                  data: data.doctors.map(({ total }) => total),
                },
              ],
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};
