import React, { FC, memo, useMemo } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { green, red } from '@ant-design/colors';

export const NextTableCheckCell: FC<any> = memo<any>(({ cell: { value } }) => {
  const wrapperProps = useMemo(
    () => ({
      style: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        flex: 1,
      },
    }),
    [],
  );

  const checkProps = useMemo(
    () => ({
      style: { color: green[5] },
    }),
    [],
  );

  const closeProps = useMemo(
    () => ({
      style: { color: red[5] },
    }),
    [],
  );

  return <div {...wrapperProps}>{!!value ? <CheckOutlined {...checkProps} /> : <CloseOutlined {...closeProps} />}</div>;
});
