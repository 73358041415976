import { PaymentFormData, PaymentFormDataApi } from '../types/payment';

export class PaymentMappers {
  public static formDataToApi(values: PaymentFormData): PaymentFormDataApi {
    return {
      visit_id: values.appointmentId,
      cash: values.cash,
      bank: values.bank,
      bonus: values.bonus,
      deposit: values.deposit,
    };
  }
}
