import React, { FC } from 'react';
import { NextTable } from '../../../shared/modules/next-table/containers';
import { CashRegisterChecksTableContext } from '../context';
import { useCashRegisterChecksTable } from '../hooks/use-checks-table';

export const CashRegisterChecksTable: FC<{ limited?: boolean }> = ({ limited }) => {
  const { columns } = useCashRegisterChecksTable({ limited });

  return <NextTable context={CashRegisterChecksTableContext} columns={columns} />;
};
