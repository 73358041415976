import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { ApiResponse } from '../../../shared/utils/api-response';
import { Status, StatusFormData, StatusFormDataApi, StatusFormErrors } from '../types';
import { StatusMappers } from '../mappers';
import { appendToFormData } from '../../../shared/utils/apply-to-form-data';
import { NextTableMetaResponse } from '../../../shared/modules/next-table/types';
import { nextTableMetaMapper } from '../../../shared/modules/next-table/utils/meta-mapper';

export class StatusService {
  public static async getAll(
    query?: Record<string, any>,
  ): Promise<ApiResponse<200, Status[], NextTableMetaResponse> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/states', query);

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data, ...meta } = await response.json();
        return new ApiResponse(200, StatusMappers.manyFromApi(data), nextTableMetaMapper(meta));
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }

  public static async getOneById(id: string | number): Promise<ApiResponse<200, Status> | ApiResponse<400 | 500, string>> {
    const url = urlMaker('/api/states/info', { state_id: id });

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        return new ApiResponse(200, StatusMappers.fromApi(data), null);
      } else {
        return new ApiResponse(400, 'Məlumatlar əldə edilə bilmədi.', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async createOrUpdate(
    values: StatusFormData,
    type: string = 'create',
  ): Promise<ApiResponse<200> | ApiResponse<422, StatusFormErrors> | ApiResponse<400 | 500, string>> {
    const url = type === 'create' ? urlMaker('/api/states/create') : urlMaker('/api/states/edit');
    const body = new FormData();

    appendToFormData<StatusFormDataApi>(StatusMappers.formDataToApi(values), body);

    try {
      const response = await caller(url, { body, method: 'POST' });

      if (response.ok) {
        return new ApiResponse(200, null, null);
      } else if (response.status === 422) {
        const { errors } = await response.json();

        return new ApiResponse(422, StatusMappers.formErrorsFromApi(errors), null);
      } else {
        return new ApiResponse(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResponse(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }
}
